import { environment } from '../../../environments/environment';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpTokenInterceptor } from '../interceptors';

// Factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, environment.translationsPrefix, environment.translationsSuffix);
}

export const httpInterceptorProviders = {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpTokenInterceptor,
    multi: true
};


