import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { toastConfig } from '../../utility';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  progressBar: boolean = true;

  constructor(
    private toastr: ToastrService) { }

  showSuccess(message?: string, title?: string) {
    this.toastr.success(message, title, toastConfig);
  }

  showSuccessTimered(message?: string, title?: string,time?:number) {
    let copyToastConfig =  {...toastConfig}
    copyToastConfig.timeOut = time;
    this.toastr.success(message, title, copyToastConfig);
  }

  showError(message?: string, title?: string) {
    this.toastr.error(message, title, toastConfig);
  }

  showWarning(message?: string, title?: string) {
    this.toastr.warning(message, title, toastConfig);
  }

  showInfo(message?: string, title?: string) {
    this.toastr.info(message, title, toastConfig);
  }

  showWarningOnlyOne(message?: string, title?: string) {
    if (this.toastr.currentlyActive === 0)
      this.toastr.warning(message, title, toastConfig);
  }

}
