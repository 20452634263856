import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { sha512 } from 'js-sha512';
import { environment } from '../../../../environments/environment';
import { Login } from '../../models';
import { AuthService, GlobalService, ToastService } from '../../services';
import { UtilityService } from '../../services/utility/utility.service';
import { MESSAGES } from '../../utility';
import { LoginFormModel } from './login-form-model';


@Component({
  selector: 'tifinanzio-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  loginRequest: Login;
  password_type: string = "password";
  password_icon: string = "fa fa-eye";
  valid_password: boolean = true;
  dynamicFocusClass: string = '';
  projectName: string = environment.projectName.toUpperCase();

  constructor(
    private formBuilder: RxFormBuilder,
    private globalService: GlobalService,
    private authService: AuthService,
    private router: Router,
    private toastService: ToastService,
    private utilityService: UtilityService

  ) {
    this.creaForm(new LoginFormModel);
  }

  ngOnInit(): void {
  }

  creaForm(lfm: LoginFormModel) {
    this.form = this.formBuilder.formGroup(lfm);
    this.form.controls.t_password.statusChanges.subscribe(status => {
      this.valid_password = this.form.controls.t_password.valid;
    });
  }

  set t_email(t_email: string) {
    this.form.get('t_email').setValue(t_email);
  }

  get t_email() {
    return this.form.get('t_email').value;
  }

  set t_password(t_password: string) {
    this.form.get('t_password').setValue(t_password);
  }

  get t_password() {
    return this.form.get('t_password').value;
  }

  login() {
    if (this.globalService.validateForm(this.form)) {
      let email = this.t_email.trim();
      this.t_email = email;
      this.loginRequest = {
        username: this.t_email.trim(),
        password: sha512(this.t_password.trim()),
        type: 'W'
      };
      //Prima Generate Token
      this.authService.generateToken().subscribe((result) => {
        if (result.code >= 200 && result.code < 300 && result.success) {
          this.authService.storeToken(result.data.access_token);
          this.authService.storeExpiresToken(result.data.expires_at);
          //CHIAMO LOGIN
          this.authService.login(this.loginRequest).subscribe(
            (result) => {
              if (result.code >= 200 && result.code < 300) {
                if (result.success) {
                  if (result.data.profilazione.ruolo.funzioni.length > 0) {
                    this.toastService.showSuccess("Accesso effettuato con successo");
                    this.getAllConstantsUtility();
                    this.router.navigate([this.globalService.getFirstPathForLandingPage()]);
                  } else {
                    this.toastService.showWarning(MESSAGES.utenzaNonAttiva);
                  }
                } else {
                  this.toastService.showError(result.descrizione);
                  this.router.navigate([environment.loginPage]);
                }
              } else {
                this.toastService.showError(result.descrizione);
                this.router.navigate([environment.loginPage]);
              }
            });
        }
      });
    }

  }

  passwordDimenticataPage() {
    this.router.navigate(['/forgot-password']);
  }

  showHidePassword() {
    if (this.password_type === 'password') {
      this.password_type = 'text';
      this.password_icon = 'fa fa-eye-slash';
    } else {
      this.password_type = 'password';
      this.password_icon = 'fa fa-eye'
    }
  }

  checkStatus() {
    if (this.form.controls.t_password.value === "") {
      console.log('ciao');
    }
  }

  deFocusContainer() {
    this.valid_password = this.form.controls.t_password.valid;
    this.dynamicFocusClass = '';
  }
  focusContainer() {
    this.dynamicFocusClass = 'focus-cs';
  }

  getAllConstantsUtility() {
    /*forkJoin({
      allRoles: this.utilityService.getAllRuoli(),
      allPartners: this.utilityService.getPartnersInfo(),
      allRecruitmentCenter: this.utilityService.getRecruitmentCentersInfo(),
      allLevelAutism: this.utilityService.getLevelAutismInfo(),
    })
      .subscribe(({ allRoles, allPartners, allRecruitmentCenter, allLevelAutism }) => {
        if (allRoles.code >= 200 && allRoles.code < 300) {
          if (allRoles.success) {
            this.utilityService.storeRoles(allRoles.data)
          }
        }

        if (allPartners.code >= 200 && allPartners.code < 300) {
          if (allPartners.success) {
            this.utilityService.storePartners(allPartners.data)
          }
        }

        if (allRecruitmentCenter.code >= 200 && allRecruitmentCenter.code < 300) {
          if (allRecruitmentCenter.success) {
            this.utilityService.storeRecruitmentCenter(allRecruitmentCenter.data)
          }
        }

        if (allLevelAutism.code >= 200 && allLevelAutism.code < 300) {
          if (allLevelAutism.success) {
            this.utilityService.storeLevelAutism(allLevelAutism.data)
          }
        }

      });*/
  }

  toLowerCase(){
    this.t_email = this.form.get('t_email').value.toLowerCase();
  }


}
