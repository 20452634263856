import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, limit: number, split: boolean = false, trail: String = '…'): string {
    let result = value || '';
    if (value) {
      if(split){
        const words = value.split(/\s+/);
        if (words.length > Math.abs(limit)) {
          if (limit < 0) {
            limit *= -1;
            result = trail + words.slice(words.length - limit, words.length).join(' ');
          } else {
            result = words.slice(0, limit).join(' ') + trail;
          }
        }
      }
      else if (value.length > limit) 
        result = value.slice(0, limit) + trail;      
    }
    return result;
  }
}
