import { NgxUiLoaderConfig, NgxUiLoaderHttpConfig, NgxUiLoaderRouterConfig, SPINNER } from 'ngx-ui-loader';

import { environment } from '../../../environments/environment';
import { HTTP_PATH_TO_EXLUDE } from './global-constants';

// Loader
export const ngxUiLoaderConfig: NgxUiLoaderConfig = {
	fgsType: SPINNER.threeBounce,
	fgsSize: 40,
	pbThickness: 3,
	bgsOpacity: 1,
	bgsPosition: "center-center",
	bgsSize: 40,
	bgsType: SPINNER.threeBounce,
	blur: 0,
	fgsPosition: "center-center",
	fgsColor: environment.primaryColor,
	overlayBorderRadius: "0",
	hasProgressBar: true,
	pbColor: environment.primaryColor,
	overlayColor: "rgba(40, 40, 40, 0.2)",
};

export const ngxUiLoaderRouterConfig: NgxUiLoaderRouterConfig = {
	excludeRegexp: ["login"],
};

export const ngxUiLoaderHttpConfig: NgxUiLoaderHttpConfig = {
	showForeground: true,
	excludeRegexp: HTTP_PATH_TO_EXLUDE,
};
