<!--sidebar-->
<c-sidebar #sidebar="cSidebar" class="d-print-none sidebar sidebar-fixed" id="sidebar" visible>
  <c-sidebar-brand [brandFull]="{src: 'assets/img/brand/logo.png', width: 90, height: 35, alt: 'TiFinanzio'}"
    [brandNarrow]="{src: 'assets/img/brand/sygnet.png', width: 50, height: 35, alt: 'TiFinanzio'}" routerLink="./">
  </c-sidebar-brand>
  <c-sidebar-nav [navItems]="navItems" dropdownMode="close">
  </c-sidebar-nav>
  <c-sidebar-toggler toggle="unfoldable" cSidebarToggle="sidebar"></c-sidebar-toggler>
</c-sidebar>
<div class="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
  <app-default-header [sidebar]="sidebar" class="mb-4 d-print-none header header-sticky" position="sticky"
    sidebarId="sidebar">
  </app-default-header>
  <div class="body flex-grow-1 px-3">
    <c-container fluid class="h-auto">
      <router-outlet></router-outlet>
    </c-container>
  </div>
  <app-default-footer class="bg-white"></app-default-footer>
</div>
