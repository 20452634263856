<div [formGroup]="form">
	<div class="datepicker-container">
		<mat-form-field class="datepickerClass"> 
			<mat-icon matDatepickerToggleIcon (click)="clearDate($event)">clear</mat-icon>
			<input matInput  formControlName="dateInput" 
			[matDatepicker]="datepicker" placeholder="DD/MM/YYYY"  
			(keyup)="onKeyupValue($event)"> 
			<mat-datepicker-toggle matSuffix [for]="datepicker">                 
			</mat-datepicker-toggle> 
			<mat-datepicker #datepicker (closed)="onChangeValue()"
			></mat-datepicker> 
		</mat-form-field> 
	</div>
</div>