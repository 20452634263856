import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { IconSetService } from '@coreui/icons-angular';

import { LocaleService } from './core/services/locale/locale.service';
import { iconSubset } from './icons/icon-subset';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'body',
	template: '<ngx-ui-loader></ngx-ui-loader><router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
	title = 'TiFinanzio';

	constructor(private titleService: Title, private iconSetService: IconSetService, private localeService: LocaleService) {
		titleService.setTitle(this.title);
		// iconSet singleton
		iconSetService.icons = { ...iconSubset };
	}

	ngOnInit(): void {}
}
