import { Injectable } from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { CacheService } from 'ng2-cache';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Funzionalita, Funzione } from '../../models';
import { DEFAULT_PAGE, PATH } from '../../utility';
import { AuthService } from '../auth/auth.service';

const CACHE = "CACHE";
const ENCRYPT_PARAMETERS = true;
const URI_ENCODE_PARAMETERS = false;
const ENCODE_TEXT_TO_REPLACE = [/\//g, /\+/g, /\=/g];
const ENCODE_TEXT_REPLACE = ["WIrCxa", "hYAIs", "xoNTx"];
const DECODE_TEXT_TO_REPLACE = [/WIrCxa/g, /hYAIs/g, /xoNTx/g];
const DECODE_TEXT_REPLACE = ["/", "+", "="];

@Injectable({
	providedIn: "root",
})
export class GlobalService {
	all_: string;
	mm: number;
	paramsSecret: string;
	cacheDate: Date;
	public readonly debug = true;
	currentTimeInSeconds: number;
	bellSubject$: BehaviorSubject<null> = new BehaviorSubject<null>(null);
	timeIntervalObservable: any; //da migliorare

	constructor(protected _cacheService: CacheService, private router: Router, private authService: AuthService) {
		this.all_ = ". ".repeat(1000);
		this.mm = 72 / 25.6;
		this.paramsSecret = "6QtxgFdnb9bBGm@7[s<A8h]X+%$B%FY&";
		this.cacheDate = new Date(0);
	}

	public getCache(objectName: string, key: string, defVal?: any): any {
		this.clearCacheIfNeeded();
		const cache = this._cacheService.get(CACHE);
		return (cache && cache[objectName] && cache[objectName][key]) || defVal;
	}

	public setCache(objectName: string, key: string, value: any) {
		this.clearCacheIfNeeded();
		let cache = this._cacheService.get(CACHE);
		if (!cache) {
			cache = {};
		}
		let objCache = cache[objectName];
		if (!objCache) {
			objCache = {};
			cache[objectName] = objCache;
		}
		objCache[key] = value;
		this._cacheService.set(CACHE, cache);
	}

	private clearCacheIfNeeded() {
		const now = new Date();
		now.setHours(0, 0, 0, 0);
		const cache = this.cacheDate;
		cache.setHours(0, 0, 0, 0);
		if (now > this.cacheDate) {
			this.cacheDate = now;
			this._cacheService.remove(CACHE);
		}
	}

	removeRouteCache(object_name: string, key: string, prev_path: string) {
		this.router.events
			.pipe(
				filter((event) => {
					return event instanceof NavigationStart;
				})
			)
			.subscribe((event: NavigationStart) => {
				if (event.navigationTrigger === "imperative") {
					if (!event.url.includes(prev_path)) {
						this.setCache(object_name, key, null);
					}
				}
			});
	}

	public decodeParams(sparams: string): any {
		if (sparams) {
			let params;
			if (ENCRYPT_PARAMETERS) {
				params = JSON.parse(CryptoJS.AES.decrypt(this.addSpecialCharacters(sparams), this.paramsSecret).toString(CryptoJS.enc.Utf8));
			} else if (URI_ENCODE_PARAMETERS) {
				params = JSON.parse(decodeURI(sparams));
			} else {
				params = JSON.parse(atob(sparams));
			}
			return params;
		}
		return {};
	}

	addSpecialCharacters(params): string {
		return params.replace(DECODE_TEXT_TO_REPLACE[0], DECODE_TEXT_REPLACE[0]).replace(DECODE_TEXT_TO_REPLACE[2], DECODE_TEXT_REPLACE[2]);
	}

	public encodeParams(params: any): string {
		this.removeEmpty(params);
		params[" "] = new Date().getTime();
		if (ENCRYPT_PARAMETERS) {
			return this.removeSpecialCharacters(CryptoJS.AES.encrypt(JSON.stringify(params), this.paramsSecret).toString());
		}
		if (URI_ENCODE_PARAMETERS) {
			return encodeURI(JSON.stringify(params));
		}
		return btoa(JSON.stringify(params));
	}

	removeSpecialCharacters(params): string {
		return params.toString().replace(ENCODE_TEXT_TO_REPLACE[0], ENCODE_TEXT_REPLACE[0]).replace(ENCODE_TEXT_TO_REPLACE[2], ENCODE_TEXT_REPLACE[2]);
	}

	public encodeObject(params: any): string {
		this.removeEmpty(params);
		params[" "] = new Date().getTime();
		return this.removeSpecialCharacters(CryptoJS.AES.encrypt(JSON.stringify(params), this.paramsSecret).toString());
	}

	public decodeObject(sparams: string): any {
		if (sparams) {
			let params;
			params = JSON.parse(CryptoJS.AES.decrypt(this.addSpecialCharacters(sparams), this.paramsSecret).toString(CryptoJS.enc.Utf8));
			return params;
		}
		return {};
	}

	removeEmpty = (obj) => {
		Object.keys(obj).forEach((key) => {
			if (obj[key] && typeof obj[key] === "object") {
				this.removeEmpty(obj[key]);
			} else if (obj[key] == null) {
				delete obj[key];
			} else if (obj[key] instanceof Array && obj[key].length === 0) {
				delete obj[key];
			}
		});
	};

	validateForm(form: FormGroup): boolean {
		if (!form.valid) {
			Object.keys(form.controls).forEach((field) => {
				const control = form.get(field);
				control.markAsTouched({ onlySelf: true });
			});
			if (this.debug) {
				this.logFormErrors(form);
			}
		}
		return form.valid;
	}

	validateForms(form: FormGroup[]): boolean {
		let valid = true;
		if (form.length < 1) {
			return;
		}
		for (let i = 0; i < form.length; i++) {
			if (form[i]) {
				Object.keys(form[i].controls).forEach((field) => {
					if (!form[i].valid) {
						valid = false;
					}
					const control = form[i].get(field);
					control.markAsTouched({ onlySelf: true });
				});
			}
		}
		return valid;
	}

	logFormErrors(form: FormGroup) {
		if (!form.valid) {
			Object.keys(form.controls).forEach((key) => {
				const controlErrors: ValidationErrors = form.get(key).errors;
				if (controlErrors) {
					Object.keys(controlErrors).forEach((keyError) => {
						//console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
					});
				}
			});
		}
	}

	//usa questo EMMA
	getLocaleDateStringJSON(data: Date) {
		const formattedData = data.toLocaleDateString();
		let [dd, mm, yyyy] = formattedData.split("/");
		if (Number(dd) < 10) {
			dd = "0" + dd;
		}
		if (Number(mm) < 10) {
			mm = "0" + mm;
		}
		const formattedHour = data.toLocaleTimeString();
		return yyyy + "-" + mm + "-" + dd + "T" + formattedHour + ".000Z";
	}

	getLocaleDateString(data: Date) {
		console.log(new Date(data.toISOString()));
		const formattedData = data.toISOString().split("T")[0];
		const formattedHour = data.toLocaleTimeString();
		return formattedData + "T" + formattedHour + ".000Z";
	}

	//togliendo il time zone
	getRealDateSTM(data: Date): Date {
		let nuovaData = new Date(data);
		let hours: number = nuovaData.getHours();
		let timeZone: number = nuovaData.getTimezoneOffset();
		nuovaData.setHours(hours - timeZone / 60);
		return nuovaData;
	}

	//includendo il time zone
	getRealDateCTM(data: Date): Date {
		data.setHours(data.getHours() + data.getTimezoneOffset() / 60);
		return data;
	}

	convertBase64ToBlob(base64: string): Blob {
		let pos = base64.indexOf(";base64,");
		let type = base64.substring(5, pos);
		let b64 = base64.substr(pos + 8);
		// decode base64
		let imageContent = atob(b64);
		// create an ArrayBuffer and a view (as unsigned 8-bit)
		let buffer = new ArrayBuffer(imageContent.length);
		let view = new Uint8Array(buffer);
		// fill the view, using the decoded base64
		for (let n = 0; n < imageContent.length; n++) {
			view[n] = imageContent.charCodeAt(n);
		}
		// convert ArrayBuffer to Blob
		let blob = new Blob([buffer], { type: type });
		return blob;
	}

	getValueByKey(map: Map<string, string>, key: string): string {
		return map.get(key);
	}

	autoGenerateEmail(nome: string, cognome: string, dominio: string) {
		this.currentTimeInSeconds = Math.floor(Date.now() / 1000);
		return (nome + cognome + this.currentTimeInSeconds + "@" + dominio)
			.normalize("NFD")
			.replace(/[\u0300-\u036f]/g, "")
			.replace(/\s/g, "")
			.toLocaleLowerCase();
	}

	getUrlIntoInfo(info: string): string {
		let url;
		if (info.split("|").length > 1) {
			url = info.split("|")[1];
		} else {
			url = "";
		}
		return url;
	}

	checkPathAllowed(path: string): boolean {
		let result: boolean = false;
		for (let percorso of PATH.path_allowed) {
			if (path.includes(percorso)) {
				result = true;
			}
		}
		return result;
	}

	checkIsNavigationAllowed(path: string): boolean {
		//  console.log(path)
		let result: boolean = false;
		if (this.checkPathAllowed(path)) {
			result = true;
		} else {
			let functions: Funzione[] = this.authService.getProfilazioneUtente().ruolo.funzioni;
			if (functions && functions.length > 0) {
				for (let funzione of functions) {
					for (let funzionalita of funzione.funzionalita) {
						if (this.getUrlIntoInfo(funzionalita.t_info_aggiuntive) === path) {
							result = true;
						}
					}
				}
			}
		}
		return result;
	}

	disableAndResetFields(formGroup: FormGroup, keys: string[], reset: boolean, disable: boolean) {
		keys.forEach((key) => {
			reset && formGroup.get(`${key}`)?.reset();
			disable && formGroup.get(`${key}`)?.disable();
		});
	}

	enableAndResetFields(formGroup: FormGroup, keys: string[], reset: boolean, enable: boolean) {
		keys.forEach((key) => {
			reset && formGroup.get(`${key}`)?.reset();
			enable && formGroup.get(`${key}`)?.enable();
		});
	}

	getFirstPathForLandingPage() {
		let functionsMenu: Funzione[] = this.authService.getProfilazioneUtente().ruolo.funzioni;
		let resCheckFunctions: boolean = this.checkIfPresentAlmostOneFunctions(functionsMenu);
		let resCheckFunctionality: boolean = false;
		if (resCheckFunctions) {
			resCheckFunctionality = this.checkIfPresentAlmostOneFunctionality(functionsMenu[0].funzionalita);
		} else {
			return DEFAULT_PAGE.errorPage404;
		}
		if (!resCheckFunctionality) {
			return DEFAULT_PAGE.errorPage404;
		} else {
			return this.getUrlIntoInfo(functionsMenu[0].funzionalita[0].t_info_aggiuntive);
		}
	}

	checkIfPresentAlmostOneFunctionality(functionality: Funzionalita[]) {
		return functionality && functionality.length > 0;
	}

	checkIfPresentAlmostOneFunctions(functions: Funzione[]) {
		return functions && functions.length > 0;
	}
}
