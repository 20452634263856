import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ValidationErrors } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatRadioButton } from '@angular/material/radio';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

import { LocaleService } from '../../../services/locale/locale.service';
import { ColStructure, DynamicMatConfiguration } from '../../models';

@Component({
	selector: 'tifinanzio-dynamic-mat-table',
	templateUrl: './dynamic-mat-table.component.html',
	styleUrls: ['./dynamic-mat-table.component.scss'],
})
export class DynamicMatTableComponent implements OnInit {
	@Input() dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
	@Input() displayedColumns: string;
	@Input() configuration: DynamicMatConfiguration;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;
	@Output() onTableCheck: EventEmitter<any> = new EventEmitter();
	@Output() onRowSelect: EventEmitter<any> = new EventEmitter();
	@Output() onRowCheck: EventEmitter<any> = new EventEmitter();
	@Output() onTableCheckAll: EventEmitter<any> = new EventEmitter();
	checkedElements = new SelectionModel<any>(true, []);
	selectedElement = new SelectionModel<any>(true, []);
	radiedElement = new SelectionModel<any>(false, []);
	colNames: string[];
	colInputs: ColStructure[];

	formRows: FormArray = this.fb.array([]);
	form: FormGroup = this.fb.group({ datas: this.formRows });

	constructor(private datePipe: DatePipe, public localeService: BsLocaleService, private fb: FormBuilder, public ls: LocaleService, private cdr: ChangeDetectorRef) {
		ls.bsConfig$.subscribe((l: any) => {
			this.localeService.use(l.locale);
		});
	}

	ngOnInit(): void {		

		if (this.configuration?.isCheckable) {
			this.configuration.columns.splice(0, 0, { name: 'checkable' });
		}
		if (this.configuration?.isRadio) {
			this.configuration.columns.splice(0, 0, { name: 'radiable' });
		}
		if (this.configuration.columns) {
			this.colNames = this.configuration.columns.map((c) => c.name);
			this.colInputs = this.configuration.columns.filter((c) => c.isInput);
		}

		this.dataSource.connect().subscribe((cnt) => {
			if (cnt && cnt.length > 0) {
				this.formRows.clear();
				cnt.forEach((ds) => {
					const formRow = this.fb.group({});
					this.colInputs?.forEach((ci) => {
						formRow.addControl(ci?.modelName, this.fb.control(ds[ci?.modelName]));
						formRow?.get(ci?.modelName)?.valueChanges.subscribe((v) => {
							ds[ci?.modelName] = v;
							this.cdr.detectChanges();
						});
					});

					try {
						this.configuration?.inputValidatorFn(formRow); //send form row controller to parent
					} catch (error) {}
					this.formRows.push(formRow);
				});
			/*	this.checkedElements = new SelectionModel(true, this.dataSource.data.filter(elem => elem.checked === true));
				this.isAllSelected();*/
			}
		});

		this.cdr.detectChanges();
	}

	ngAfterViewInit(): void {
		this.setPagination();		
		this.cdr.detectChanges();
	}

	setPagination() {
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;		
	}

	isAllSelected() {
		this.checkedElements = new SelectionModel(true, this.dataSource.data.filter(elem => elem.checked === true));
		const numSelected = this.checkedElements.selected.length;
		const numRows = this.dataSource.data.length;
		return numSelected === numRows;
	}

	toggleAllRows() {
		if (this.isAllSelected()) {
			this.checkedElements.clear();
			this.onTableCheck.emit(this.checkedElements.selected);
			this.onTableCheckAll.emit(this.checkedElements.selected);
			return;
		}

		this.checkedElements.select(...this.dataSource.data);
		this.onTableCheck.emit(this.checkedElements.selected);
		this.onTableCheckAll.emit(this.checkedElements.selected);
	}

	toggleRow(row: any) {
		this.checkedElements.toggle(row);
		this.onTableCheck.emit(this.checkedElements.selected);
	}

	colRender(row: any, colName: string) {
		const column = this.configuration.columns.find((c) => c.name === colName);
      
		if (column && column.usePipe) {
			return column.usePipe(row);
		}

		if (row[colName] instanceof Date) {
			return this.datePipe.transform(row[colName], 'dd/MM/yyyy');
		} else {
			return row[colName];
		}
	}

	colTooltip(row: any, colName: string) {
		return row[colName];
	}

	selectRow(row: any) {
		if (this.configuration.isSelectable) {
			if (!this.selectedElement.isSelected(row)) {
				this.selectedElement.clear();
				this.selectedElement.toggle(row);
				this.onRowSelect.emit(row);
			} else {
				this.selectedElement.clear();
				this.onRowSelect.emit(null);
			}
		}
	}

	checkRow(row: any, radio: MatRadioButton) {
		if (this.configuration?.disableRadio && !this.configuration?.disableRadio(row)) {
			if (this.radiedElement.isSelected(row)) {
				this.radiedElement.clear();
				setTimeout(() => {
					radio.checked = false;
				}, 10);
				this.onRowCheck.emit(null);
			} else {
				this.radiedElement.select(row);
				this.onRowCheck.emit(row);
			}
		}
	}

	renderTooltip(tooltip: string | Function, row: any) {
		if (tooltip) {
			if (typeof tooltip === 'function') {
				return tooltip(row);
			} else {
				return tooltip;
			}
		}
	}

	printErrorMessages(errors: ValidationErrors) {
		let errorMessage: string = '';
		if (errors && Object.values(errors).length > 0) {
			const error = Object.values(errors)[0];
			errorMessage = error.message;
		}
		return errorMessage;
	}
}
