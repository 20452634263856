import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DocumentationCreateFormModel } from '../../../../app/modules/product/forms';
import { Option }  from '../../components';
import { GenericItemSelect } from '../../models';
import { UtilityService } from '../../services';
import { DIALOG_CANCEL, DIALOG_SAVE, nMaxLenghtInput } from '../../utility';

@Component({
  selector: 'app-edit-dialog',
  templateUrl: './edit-dialog.component.html'
})
export class EditDialogComponent implements OnInit {
  title: string;
  messages: string;
  objEditable: any;
  options: Array<Option>;
  answer: Option;
  typeObject: string;
  form: FormGroup;
  nMaxLenghtInput: number = nMaxLenghtInput;
  isDocumentation: boolean;
  isEdit: boolean;

  stages: GenericItemSelect[];

  constructor( public bsModalRef: BsModalRef,
               private utilityService: UtilityService,
               private formBuilder: RxFormBuilder,
               private changeDetectorRef: ChangeDetectorRef
             ) {
     this.callService();
  }

  callService(){
    this.stages = this.utilityService.getListStages();
  }

  respond(answer?: Option){
    if(!answer || answer.key === DIALOG_CANCEL || (answer.key === DIALOG_SAVE && this.form.valid )) {
      this.answer = answer ? answer : new Option(null, null);
      this.bsModalRef.hide();
    }
  }

  ngOnInit() { 
    this.isDocumentation = this.typeObject === 'doc';   
  }

  ngAfterViewInit(){
    this.setForm();
    this.isEdit = this.form.controls['n_id'] && this.form.controls['n_id'].value ? true : false;
  }

  setForm(){
    if(this.isDocumentation) {
      this.form = this.formBuilder.formGroup(new DocumentationCreateFormModel())
    }
    this.form.patchValue(this.objEditable);
    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.form.updateValueAndValidity();
  }

  ctrlValidity(field :string){
    return  this.form.get(field) && this.form.get(field).touched && !this.form.get(field).valid;
  } 

}


