// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environment = {
    production: false,
    apiBaseUrl: 'prophet/',
    //apiBaseUrl: 'tifinanzio/',
    //apiBaseUrl: 'https://fenice.svil.itsvil.it/prophet/',
    pathTifinanzio: 'application/finance/tifinanzio/',
    //pathTifinanzio: '',
    homePage: 'home',
    loginPage: '/login',
    logoutPage: '/login',
    notFound: '/404',
    projectName: 'TiFinanzio',
    projectYear: '2023',
    footerLink: 'https://tifinanzioazienda.it/',
    defaultLocale: 'it',
    translationsPrefix: '/assets/i18n/',
    translationsSuffix: '.json',
    translationsListConfig: '/assets/i18n/config.json',
    minuti_inattivita: 120,
    locale_key: 'TiFinanzio_locale',
    idProject: 'tifinanzio',
    staticProfile: false,
    internalEnabled: false,
    primaryColor: '#2377D4',
    secondaryColor: '#1A1B41',
    thirdColor: '#79757D',
    applicationUsername: 'tifinanziofe',
    applicationPassword: 'tifinanziofe',
    checkDocumentLength: true,
};

export const idle = {
    idletimepercentage: 0.8,
    keepaliveinterval: 1,
    idletimefixed: 90,
};

export const http_headers = {
    authorization: 'Authorization',
    basicAuthorization: 'Basic ',
    bearerAuthorization: 'Bearer ',
};
