import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
	{
		name: 'User',
		url: '/user',
		iconComponent: { name: 'cil-puzzle' },
		children: [
			{
				name: 'Create',
				iconComponent: { name: 'cil-puzzle' },
				url: '/user/create',
			},
			{
				name: 'Search',
				iconComponent: { name: 'cil-puzzle' },
				url: '/user/search',
			},
		],
	},
	{
		name: 'Practice',
		url: '/practice',
		iconComponent: { name: 'cil-puzzle' },
		children: [
			{
				name: 'Search',
				iconComponent: { name: 'cil-puzzle' },
				url: '/practice/search',
			},
			{
				name: 'Create',
				iconComponent: { name: 'cil-puzzle' },
				url: '/practice/create',
			}
		],
	},
	{
		name: 'Product',
		url: '/product',
		iconComponent: { name: 'cil-puzzle' },
		children: [
			{
				name: 'List',
				iconComponent: { name: 'cil-puzzle' },
				url: '/product/search',
			}
		],
	},
	{
		name: 'Customer',
		url: '/customer',
		iconComponent: { name: 'cil-puzzle' },
		children: [
			{
				name: 'Create',
				iconComponent: { name: 'cil-puzzle' },
				url: '/customer/create',
			},
			{
				name: 'Search',
				iconComponent: { name: 'cil-puzzle' },
				url: '/customer/search',
			}
		],
	},
	{
		name: 'Revenue',
		url: '/revenue',
		iconComponent: { name: 'cil-puzzle' },
		children: [			
			{
				name: 'Data',
				iconComponent: { name: 'cil-puzzle' },
				url: '/revenue/search',
			}
		],
	},
];
