import { JwtModuleOptions } from '@auth0/angular-jwt';

//Token JWT
export const ACCESSTOKENKEY = 'access_token';

export function jwtTokenGetter() {
    return sessionStorage.getItem(ACCESSTOKENKEY);
}

export const jwtModuleOptions: JwtModuleOptions = {
      config: {
        tokenGetter: jwtTokenGetter,
         disallowedRoutes: [
          "/prophet/authentication/ammit/oauth2/1.0/generateToken",
        ], 
      }
} 