<!--<c-header class="mb-4 d-print-none" position="sticky">-->
<ng-container>
  <c-container [fluid]="true">
    <c-row class="w-100">
      <c-col class="p-0">
        <button #menuBtn toggle="visible" cHeaderToggler [cSidebarToggle]="sidebarId" class="ps-1 d-md-none"
          [ngClass]="{'ps-1': isVisible(menuBtn)}">
          <svg cIcon name="cilMenu" size="lg"></svg>
        </button>
      </c-col>
      <c-col class="d-flex justify-content-end p-0">
        <c-header-nav class="ms-3">
          <app-locale-dropdown></app-locale-dropdown>
          <c-dropdown alignment="end" variant="nav-item">
            <button cButton color="" [caret]="false" cDropdownToggle class="py-0 border-0">
              <span class="iconify" data-icon="fa:user-circle-o" data-width="19" data-height="19"></span>
            </button>
            <ul cDropdownMenu class="p-0 w-auto">
              <li>
                <h4 cDropdownHeader class="bg-light fw-semibold text-center m-0"><strong>Account</strong></h4>
              </li>
              <li>
                <button class="dropdown-item py-2 " (click)="goToProfile()">
                  <span class="iconify me-3" data-icon="fa:user" data-width="10" data-height="10"></span>Profilo
                </button>
              </li>
              <li>
                <button class="dropdown-item py-2 " (click)="logout()">
                  <span class="iconify me-3" data-icon="fa:lock" data-width="10" data-height="10"></span>Logout
                </button>
              </li>
            </ul>
          </c-dropdown>
        </c-header-nav>
      </c-col>
    </c-row>


  </c-container>
  <c-header-divider></c-header-divider>
  <c-container [fluid]="true">
    <c-breadcrumb-router class="ms-2" #breadcrumb></c-breadcrumb-router>
  </c-container>
</ng-container>
<!--</c-header>-->
