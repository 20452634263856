import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { IconModule, IconSetModule, IconSetService } from '@coreui/icons-angular';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { CacheService, Ng2CacheModule } from 'ng2-cache';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ToastrModule } from 'ngx-toastr';
import { NgxUiLoaderHttpModule, NgxUiLoaderModule, NgxUiLoaderRouterModule } from 'ngx-ui-loader';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {
	ConfirmDialogComponent,
	DefaultFooterComponent,
	DefaultHeaderComponent,
	DefaultLayoutComponent,
	LocaleDropdownComponent,
	P404Component,
	P500Component,
	EditDialogComponent
} from './core/components';
import { CoreModule } from './core/core.module';
import { HttpTokenInterceptor } from './core/interceptors';
import { MatPaginationIntlService } from './core/material/services';
import { AuthService, GlobalService, MessageService, ToastService } from './core/services';
import { jwtModuleOptions, ngxUiLoaderConfig, ngxUiLoaderHttpConfig, translateModuleConfig } from './core/utility';

// Import containers
// Import app component
// Import routing module
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	suppressScrollX: true,
};

export function HttpResponseInterceptorFactory(
	authService: AuthService,
	router: Router,
	toastService: ToastService
) {
	return new HttpTokenInterceptor(authService, router, toastService);
}

const APP_CONTAINERS = [DefaultFooterComponent, DefaultHeaderComponent, DefaultLayoutComponent];

@NgModule({
	declarations: [AppComponent, ...APP_CONTAINERS, P404Component, P500Component, ConfirmDialogComponent, LocaleDropdownComponent, EditDialogComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		PerfectScrollbarModule,
		BsDropdownModule.forRoot(),
		TabsModule.forRoot(),
		IconModule,
		IconSetModule.forRoot(),
		ToastrModule.forRoot(), // ToastrModule added
		ReactiveFormsModule,
		RxReactiveFormsModule,
		FormsModule,
		MatTooltipModule,
		HttpClientModule,
		NgIdleKeepaliveModule.forRoot(),
		TranslateModule.forRoot(translateModuleConfig),
		NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
		NgxUiLoaderRouterModule,
		NgxUiLoaderHttpModule.forRoot(ngxUiLoaderHttpConfig),
		JwtModule.forRoot(jwtModuleOptions),
		Ng2CacheModule,
		TabsModule.forRoot(),
		NgSelectModule,
		BsDatepickerModule.forRoot(),
		CoreModule,
	],
	providers: [
		{
			provide: LocationStrategy,
			useClass: HashLocationStrategy,
		},
		{
			provide: STEPPER_GLOBAL_OPTIONS,
			useValue: { displayDefaultIndicatorType: false },
		},
		{
			provide: HTTP_INTERCEPTORS,
			useFactory: HttpResponseInterceptorFactory,
			multi: true,
			deps: [AuthService, Router, ToastService],
		},
		IconSetService,
		ToastService,
		CacheService,
		AuthService,
		HttpClient,
		MessageService,
		BsModalService,
		TranslateService,
		DatePipe,
		{
			provide: MatPaginatorIntl,
			useClass: MatPaginationIntlService,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule { }
