import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
	BreadcrumbRouterComponent,
	ClassToggleService,
	HeaderComponent,
	IBreadcrumbItem,
	SidebarComponent,
} from '@coreui/angular';
import { TranslateService } from '@ngx-translate/core';
import { PAGES } from 'src/app/core/utility';

import { BreadcrumbService, GlobalService } from '../../../services';
import { AuthService } from '../../../services/auth/auth.service';

@Component({
	selector: "app-default-header",
	templateUrl: "./default-header.component.html",
})
export class DefaultHeaderComponent extends HeaderComponent {
	@Input() sidebarId: string = "sidebar";
	@ViewChild("breadcrumb") breadCrumb: BreadcrumbRouterComponent;
	breadCrumbItems: IBreadcrumbItem[];
	breadCrumbItemsOriginal: IBreadcrumbItem[];
	@Input() sidebar: SidebarComponent;

	constructor(private classToggler: ClassToggleService, public breadcrumbService: BreadcrumbService, private translateService: TranslateService,
		private authService: AuthService, private router: Router, private activatedRoute: ActivatedRoute, private globalService: GlobalService,
		private changeDetectorRef: ChangeDetectorRef) {
		super();

	}

	ngAfterViewInit(): void {
		this.translateBreadCrumbs();
		this.changeDetectorRef.detectChanges();
	}

	translateBreadCrumbs() {
		this.breadCrumb.service.breadcrumbs$.subscribe((breadCrumbItems) => {
			this.breadCrumbItemsOriginal = breadCrumbItems;
			this.breadCrumbItems = JSON.parse(JSON.stringify(this.breadCrumbItemsOriginal));
			this.breadCrumbItemsOriginal.forEach((bci) => {				
				this.translateService.stream(`breadcrumbs.${bci.label}`).subscribe((res) => {
					setTimeout(() => { bci.label = res; }, 50);					
				});
				this.breadCrumbItemsOriginal = this.breadCrumbItems;
				this.breadCrumb.items = this.breadCrumbItems;
			});
		});
	}

	logout() {
		this.authService.logout();
		return false;
	}

	goToProfile() {
		let body = { id: this.authService.getDettaglioUtente().profilazione.t_id_user};
		this.router.navigate([PAGES.user.profile, this.globalService.encodeParams(body)], { relativeTo: this.activatedRoute });
	}

	isVisible(menuBtn: HTMLElement) {
		if (menuBtn.offsetParent === null) {
			this.sidebar.visible = true;
		}

		return true;
	}
}
