import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserSearchResponse } from 'src/app/modules/user/models';
import { environment } from '../../../../environments/environment';
import { ResponsePaginatedDTO, RisultatoDTO, UploadRequest, UploadResponse, UploadSearchRequest} from '../../models';

export const UF = "file/1.0/uploadFile";
export const RF = "file/1.0/removeFile";
export const DF = "file/1.0/downloadFile";
export const GLU = "upload/1.0/getListUpload";

@Injectable({
    providedIn: 'root'
})
export class UploadService {

    constructor(private httpClient: HttpClient) { }

    uploadFile(uploadRequest: UploadRequest): Observable<RisultatoDTO<UploadResponse>>  {
      var formData: FormData = new FormData();
      formData.append("file", uploadRequest.file);
      formData.append("fullName", uploadRequest.fullName);
      return this.httpClient.post<RisultatoDTO<UploadResponse>>(`${environment.apiBaseUrl}${environment.pathTifinanzio}${UF}`,formData);
    }

    removeFile(path:string): Observable<RisultatoDTO<string>>{
      let param = "?fileCode=" + path; 
      return this.httpClient.post<RisultatoDTO<string>>(`${environment.apiBaseUrl}${environment.pathTifinanzio}${RF}${param}`,{});
    }

    downloadFile(path:string): Observable<Blob>{
      let param = "?fileCode=" + path; 
      return this.httpClient.post(`${environment.apiBaseUrl}${environment.pathTifinanzio}${DF}${param}`,{},{ responseType: 'blob' });
    }

    getListUpload(uploadSearchRequest: UploadSearchRequest): Observable<RisultatoDTO<ResponsePaginatedDTO<UserSearchResponse>>> {
      return this.httpClient.post<RisultatoDTO<ResponsePaginatedDTO<UserSearchResponse>>>(`${environment.apiBaseUrl}${environment.pathTifinanzio}${GLU}`, uploadSearchRequest);
    }
    
}