import { prop } from "@rxweb/reactive-form-validators";

export class ProductSearchFormModel {

    @prop()
    n_id: string;
    
	@prop()
    t_name: string;

    @prop()
    d_date_start: string;

    @prop()
    d_date_end: string;

    constructor() {
        this.n_id = null;
        this.t_name = null;
        this.d_date_start = null;    
        this.d_date_end = null; 
    }
}
