import { Component, OnInit } from '@angular/core';

import { LocaleService } from '../../services';

@Component({
	selector: "app-locale-dropdown",
	templateUrl: "./locale-dropdown.component.html",
})
export class LocaleDropdownComponent implements OnInit {
	public menuLabel: string;

	constructor(public localeService: LocaleService) {}

	ngOnInit(): void {
		this.setMenuLabel(this.localeService.localStorageLang);
	}

	switchLocale(locale: string) {
		this.localeService.use(locale);
		this.setMenuLabel(locale);
	}

	private setMenuLabel(id: string) {
		for (let i = 0; i < this.localeService.supportedLocales.length; i++) {
			const locale = this.localeService.supportedLocales[i];
			if (locale.id === id) {
				this.menuLabel = locale.menuLabel;
				return;
			}
		}
	}
}
