<div [formGroup]="form" *ngIf="form" class="row d-block m-0 p-0">
    <mat-card-header class="mx-0 px-0">
        <mat-card-title>            
            <div class="row pt-2 pb-2">  
                <div class="col-md-4 d-flex justify-content-start pt-3 p-1">
                    {{ getDynamicTitle()  | translate }} 
                </div>               
                <div class="col-md-8 d-flex justify-content-end p-1" *ngIf="bOpenDetail"> 
                    <button type="button" class="btn btn-outline-primary firstButton" (click)="deleteProduct()">
                        <i class="fa fa-trash iconMedium" aria-hidden="true"></i>                          
                    </button>
                    <button type="button" class="btn btn-outline-primary" >
                        <i class="fa fa-pencil iconMedium" aria-hidden="true" (click)="enabledEdit()"></i>                         
                    </button>
                </div>
                <div class="col-md-8 d-flex justify-content-end" *ngIf="bOpenCreate || bOpenEdit">   
                    <button type="button" class="btn btn-link btn-link--reset pr-3"
                            (click)="deleteInsert()">{{'product.detail.form.buttons.cancel' | translate}}
                    </button>
                    <button type="button" class="btn btn-primary" (click)="save()" [disabled]="getDisableBtnSave()">
                        <span>{{'product.detail.form.buttons.confirm' | translate}}</span>
                    </button>             
                </div>
            </div>
        </mat-card-title>       
    </mat-card-header>
    <mat-card-content class="py-0 px-0">        
        <div class="row m-0 p-0">
            <div class="col-md-6">            
                <div class="form-group">                   
                    <label for="t_name" class="form-label"> {{ 'product.detail.form.t_name'  | translate}} *</label>
                    <input type="text" class="form-control" formControlName="t_name" id="t_name" maxlength="{{nMaxLenghtInput}}">
                    <mat-error *ngIf="ctrlValidity('t_name')">
                        <small *ngFor="let error of form.get('t_name')['errorMessages']">
                            {{ error | translate }}
                        </small>
                    </mat-error>                                  
                </div>
            </div> 
            <div class="col-md-6">            
                <div class="form-group">
                    <label for="t_description" class="form-label"> {{'product.detail.form.t_description' | translate}}</label>
                    <input type="text" class="form-control" formControlName="t_description" id="t_description" maxlength="{{nMaxLenghtInput}}">                               
                </div>
            </div> 
        </div>        
    </mat-card-content>
</div>
