export function cacheable(cacheKey: string, cacheObjName: string, resetForm: boolean, pathToExclude: string) {
	return function cacheDecorator<T extends { new (...args: any[]): any }>(constructor: T) {
		return class extends constructor {
			prepareSearchRequest() {
				console.log('prepareSearchRequest');
				const searchRequest = super.prepareSearchRequest();
				this.setCache(searchRequest);
				return searchRequest;
			}
			setCache(healthcareSearchRequest: any) {
				console.log('setCache');
				this.globalService.setCache(cacheObjName, cacheKey, healthcareSearchRequest);
			}

			ngOnInit() {
				console.log('ngOnInit');
				super.ngOnInit();
				this.globalService.removeRouteCache(cacheObjName, cacheKey, pathToExclude);
			}

			ngAfterViewInit() {
				console.log('ngAfterViewInit');
				super.ngAfterViewInit();
				this.checkCache();
			}

			checkCache() {
				console.log('checkCache');

				const healthcareSearchRequest = this.globalService.getCache(cacheObjName, cacheKey);
				if (healthcareSearchRequest) {
					!resetForm && super.setForm(healthcareSearchRequest);
					super.search();
					return true;
				} else {
					return false;
				}
			}
		};
	};
}
