import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
	AvatarModule,
	BadgeModule,
	BreadcrumbModule,
	ButtonGroupModule,
	ButtonModule,
	CardModule,
	DropdownModule,
	FooterModule,
	FormModule,
	GridModule,
	HeaderModule,
	ListGroupModule,
	NavModule,
	ProgressModule,
	SharedModule,
	SidebarModule,
	UtilitiesModule
} from '@coreui/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { ForgotPasswordComponent, LoginComponent, MaintenanceComponent } from './components';

import { DynamicMatTableComponent, MatDatapickerComponent } from './material/components';
import { MaterialModule } from './material/material.module';
import { TruncatePipe } from './pipe/truncate/truncate.pipe';

import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
	declarations: [TruncatePipe, MaintenanceComponent, LoginComponent, ForgotPasswordComponent],
	imports: [
		CommonModule,
		TooltipModule.forRoot(),
		FormsModule,
		ReactiveFormsModule,
		MaterialModule,
		TranslateModule.forChild(),
		AlertModule.forRoot(),
		AvatarModule,
		BadgeModule,
		BreadcrumbModule,
		ButtonGroupModule,
		ButtonModule,
		CardModule,
		DropdownModule,
		FooterModule,
		FormModule,
		GridModule,
		HeaderModule,
		ListGroupModule,
		NavModule,
		ProgressModule,
		SharedModule,
		SidebarModule,
		UtilitiesModule,
	],
	exports: [
		TruncatePipe,
		MaintenanceComponent,
		DynamicMatTableComponent,
		MatDatapickerComponent,
		MaterialModule,
		AlertModule,
		AvatarModule,
		BadgeModule,
		BreadcrumbModule,
		ButtonGroupModule,
		ButtonModule,
		CardModule,
		DropdownModule,
		FooterModule,
		FormModule,
		GridModule,
		HeaderModule,
		ListGroupModule,
		NavModule,
		ProgressModule,
		SharedModule,
		SidebarModule,
		UtilitiesModule,
		MatDatepickerModule,
    	MatInputModule,
		MatFormFieldModule, 
		MatNativeDateModule, 
		MatInputModule, 
		MatDialogModule, 
		MatButtonModule, 
		MatButtonToggleModule		 
	],
})
export class CoreModule { }
