//import { GCBFP } from '../services/utility/utility.service';

export const MESSAGES = {
	notAllowed: "Non sei autorizzato",
	beforeLogin: "Effettua il login per poter utilizzare i servizi di ",
	serviceUnavailable: "Servizio non disponibile",
	notAllowedAuthentication: "Servizio non disponibile",
	moduleNotAllowed: "Il modulo EasyProfiling non è presente nel ruolo",
	utenzaNonAttiva: "Utenza non attiva",
};

export const PAGES = {
	user: {
		detail: "/user/detail",
		search: "/user/search",
		profile: "/user/profile"
	},
	customer: {
		detail: "/customer/detail",
		search: "/customer/search"
	},
	product: {
		search: "/product/search"
	},
	practice: {
		detail: "/practice/detail",
		search: "/practice/search"
	},
	bank:{
		detail:"/bank/detail",
		search: "/bank/search"
	}
};

export const TYPE_OPERATOR = {
	minusEqual: "minusEquals",
	equal: "equals",
	greaterEqual: "greaterEqual",
};

export const TYPE_BEDS = {
	woman:"Woman",
	man:"Man"
}
export const HTTP_PATH_TO_EXLUDE = [];//[GCBFP];
