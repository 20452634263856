import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { INavData, SidebarComponent } from '@coreui/angular';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../../../../environments/environment';
import { navItems } from '../../../_nav';
import { LocaleService } from '../../services';
import { AuthService } from '../../services/auth/auth.service';
import { ToastService } from '../../services/toast/toast.service';

@Component({
	selector: 'app-dashboard',
	templateUrl: './default-layout.component.html',
})
export class DefaultLayoutComponent {
	public navItems: Array<INavData>;
	public navItemsOriginal: Array<INavData>;
	@ViewChild('sidebar') sidebar: SidebarComponent;

	constructor(private translateService: TranslateService, private localeService: LocaleService, private authService: AuthService, private toastService: ToastService) {
		environment.staticProfile ? (this.navItemsOriginal = this.navItems = navItems) : this.checkProfilazione();
	}

	ngAfterViewInit(): void {
		this.translateNavItems();
	}

	translateNavItems() {
		this.localeService.bsConfig$.subscribe((res) => {
			const navItemsTemp = JSON.parse(JSON.stringify(this.navItemsOriginal));
			setTimeout(() => {
				this.navItems = navItemsTemp.map((nav) => {
					this.translateService.stream('side-slide-functionality.function-' + nav.name).subscribe((val) => {
						if (val !== 'side-slide-functionality.function-' + nav.name) {
							nav.name = val;
						}
					});
					if (nav.children) {
						nav.children = nav.children.map((child) => {
							this.translateService.stream('side-slide-functionality.function-' + child.name).subscribe((val) => {
								if (val !== 'side-slide-functionality.function-' + child.name) {
									child.name = val;
								}
							});
							return child;
						});
					}
					return nav;
				});
			}, 600);
		});
	}

	checkProfilazione() {
		this.authService.navItems$.subscribe({
			next: (items) => {
				if (items && items.length <= 1) {
					this.toastService.showError('Non sei autorizzato');
					this.authService.logout();
					return;
				}
				this.navItemsOriginal = items;
				this.navItems = items;
			},
			error: () => {
				this.toastService.showError('Non sei autorizzato');
				this.authService.logout();
			},
		});
	}
}
