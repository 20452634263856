export const PATH = {
    path_allowed: ['user/detail', 'product/detail', 'practice/detail', 'customer/detail', 'user/profile'],
};

export const DEFAULT_PAGE = {
    errorPage404: '404',
};

export const PERMISSION_FIELDS = {
    USER_DETAIL: {
        FIELDS: {
            ADDRESS: {
                ADMIN: {
                    EDIT: true,
                    CREATE: true,
                },
                COLLABORATORE: {
                    EDIT: false,
                    CREATE: false,
                },
                SOTTOCOLLABORATORE: {
                    EDIT: false,
                    CREATE: false,
                },
            },
            ROLE: {
                ADMIN: {
                    EDIT: true,
                    CREATE: true,
                },
                COLLABORATORE: {
                    EDIT: false,
                    CREATE: false,
                },
                SOTTOCOLLABORATORE: {
                    EDIT: false,
                    CREATE: false,
                },
            },
            POSTION: {
                ADMIN: {
                    EDIT: true,
                    CREATE: true,
                },
                COLLABORATORE: {
                    EDIT: false,
                    CREATE: false,
                },
                SOTTOCOLLABORATORE: {
                    EDIT: false,
                    CREATE: false,
                },
            },
            PARENT: {
                ADMIN: {
                    EDIT: true,
                    CREATE: true,
                },
                COLLABORATORE: {
                    EDIT: false,
                    CREATE: false,
                },
                SOTTOCOLLABORATORE: {
                    EDIT: false,
                    CREATE: false,
                },
            },
        },
        BUTTONS: {
            EDIT: {
                ADMIN: {
                    ALLOWED: true,
                },
                COLLABORATORE: {
                    ALLOWED: false,
                },
                SOTTOCOLLABORATORE: {
                    ALLOWED: false,
                },
            },
            DELETE: {
                ADMIN: {
                    ALLOWED: true,
                },
                COLLABORATORE: {
                    ALLOWED: false,
                },
                SOTTOCOLLABORATORE: {
                    ALLOWED: false,
                },
            },
        },
    },
    USER_SEARCH: {
        BUTTONS: {
            EDIT: {
                ADMIN: {
                    ALLOWED: true,
                },
                COLLABORATORE: {
                    ALLOWED: false,
                },
                SOTTOCOLLABORATORE: {
                    ALLOWED: false,
                },
            },
            DELETE: {
                ADMIN: {
                    ALLOWED: true,
                },
                COLLABORATORE: {
                    ALLOWED: false,
                },
                SOTTOCOLLABORATORE: {
                    ALLOWED: false,
                },
            },
        },
    },
    PRACTICE_STEP: {
        STEP_1: {
            FIELDS: {
                ALL: {
                    ADMIN: {
                        EDIT: false,
                        CREATE: true,
                    },
                    COLLABORATORE: {
                        EDIT: false,
                        CREATE: false,
                    },
                    SOTTOCOLLABORATORE: {
                        EDIT: false,
                        CREATE: false,
                    },
                },
            },
        },
        STEP_2: {
            FIELDS: {
                ALL_FIELDS: {
                    ADMIN: {
                        EDIT: true,
                        CREATE: true,
                    },
                    COLLABORATORE: {
                        EDIT: true,
                        CREATE: false,
                    },
                    SOTTOCOLLABORATORE: {
                        EDIT: false,
                        CREATE: false,
                    },
                },
            },
            BUTTONS: {
                CONFIRM: {
                    ADMIN: {
                        ALLOWED: true,
                    },
                    COLLABORATORE: {
                        ALLOWED: false,
                    },
                    SOTTOCOLLABORATORE: {
                        ALLOWED: false,
                    },
                },
                SAVE: {
                    ADMIN: {
                        ALLOWED: true,
                    },
                    COLLABORATORE: {
                        ALLOWED: true,
                    },
                    SOTTOCOLLABORATORE: {
                        ALLOWED: false,
                    },
                },
                SEND_USER_ALERT: {
                    ADMIN: {
                        ALLOWED: true,
                    },
                    COLLABORATORE: {
                        ALLOWED: false,
                    },
                    SOTTOCOLLABORATORE: {
                        ALLOWED: false,
                    },
                },
                SEND_CUSTOMER_ALERT: {
                    ADMIN: {
                        ALLOWED: true,
                    },
                    COLLABORATORE: {
                        ALLOWED: false,
                    },
                    SOTTOCOLLABORATORE: {
                        ALLOWED: false,
                    },
                },
            },
        },
        STEP_3: {
            FIELDS: {
                ALL_FIELDS: {
                    ADMIN: {
                        EDIT: true,
                        CREATE: true,
                    },
                    COLLABORATORE: {
                        EDIT: false,
                        CREATE: false,
                    },
                    SOTTOCOLLABORATORE: {
                        EDIT: false,
                        CREATE: false,
                    },
                },
            },
        },
        STEP_4: {
            FIELDS: {
                ALL_FIELDS: {
                    ADMIN: {
                        EDIT: true,
                        CREATE: true,
                    },
                    COLLABORATORE: {
                        EDIT: false,
                        CREATE: false,
                    },
                    SOTTOCOLLABORATORE: {
                        EDIT: false,
                        CREATE: false,
                    },
                },
            },
        },
        STEP_5: {
            FIELDS: {
                ALL_FIELDS: {
                    ADMIN: {
                        EDIT: true,
                        CREATE: true,
                    },
                    COLLABORATORE: {
                        EDIT: false,
                        CREATE: false,
                    },
                    SOTTOCOLLABORATORE: {
                        EDIT: false,
                        CREATE: false,
                    },
                },
            },
        },
    },
    CUSTOMER_DETAIL: {
        BUTTONS: {
            EDIT: {
                ADMIN: {
                    ALLOWED: true,
                },
                COLLABORATORE: {
                    ALLOWED: false,
                },
                SOTTOCOLLABORATORE: {
                    ALLOWED: false,
                },
            },
            DELETE: {
                ADMIN: {
                    ALLOWED: true,
                },
                COLLABORATORE: {
                    ALLOWED: false,
                },
                SOTTOCOLLABORATORE: {
                    ALLOWED: false,
                },
            },
        },
    },
};

// TODO REMOVE
export const PROFESSION_SELECT = {
    data: [
        {
            id: '1',
            name: 'Medico',
        },
        {
            id: '2',
            name: 'OSS',
        },
        {
            id: '3',
            name: 'Infermiere',
        },
        {
            id: '4',
            name: 'Amministrativo',
        },
        {
            id: '5',
            name: 'Tecnico',
        },
    ],
};

export const PROFILE = {
    menuType: 'menu',
    internalType: 'internal',
};

// TODO REMOVE
export const COCO_LANGUAGE = [
    { t_cod_language: 'IT', t_text: 'Italiano' },
    { t_cod_language: 'EN', t_text: 'Inglese' },
];

export const DEFAULT_LANGUAGE = 'IT';

export const TOKEN_UTIL = {
    apim: 'apim',
    module_ehc: 'module_ehc',
    error_ehc: 'tokennonvalido',
    paths_exclude_ehc: ['profiling/1.0/refreshToken', '/assets/i18n/it.json', 'oauth2/token', 'profiling/1.0/validateToken'],
    paths_exclude_tokenapim: ['oauth2/token'],
};

export const FORMAT_DD_MM_YYYY = 'dd/MM/yyyy';
export const DIALOG_CONFIRM = 'dialog_op_confirm';
export const DIALOG_CANCEL = 'dialog_op_cancel';
export const DIALOG_SAVE = 'dialog_op_save';
export const sACTIVE = 'Y';
export const nMinLengthSearchSelect = 3;
export const nLengthResultSearchSelect = 5;
export const nMaxLenghtInput = 255;
export const maxLengthStringToTable = 40;

export const USER_ROLE = [
    {
        t_name: 'ADMIN/OFFICE',
        n_id: 'ADMIN/OFFICE',
    },
    {
        t_name: 'COLLABORATORE',
        n_id: 'COLLABORATORE',
    },
    {
        t_name: 'SOTTOCOLLABORATORE',
        n_id: 'SOTTOCOLLABORATORE',
    },
];

export const SURVEY_DETECTION = [
    {
        key: 'Primo semestre (01/01 - 30/06)',
        value: 'primo_semestre',
    },
    {
        key: 'Secondo semestre - (01/07 - 31/12)',
        value: 'secondo_semestre',
    },
    {
        key: 'Intero Anno - (01/01 - 31/12)',
        value: 'intero_anno',
    },
];

export const MIN_MAX = {
    n_pre_depreciation: {
        min: 0,
        max: 1000,
    },
    n_bank_duration: {
        min: 0,
        max: 1000,
    },
    n_bank_amount: {
        min: 0,
        max: 1000000000,
    },
    n_duration: {
        min: 1,
        max: 1000,
    },
    n_amount: {
        min: 1,
        max: 1000000000,
    },
    n_commission: {
        min: 0,
        max: 1000000000,
    },
    t_street_number: {
        min: 1,
    },
    n_progressive_year: {
        min: 1,
    },
};

export const USER_ROLE_CONVERSION = ['ADMIN/OFFICE', 'COLLABORATORE', 'SOTTOCOLLABORATORE'];

export const PARAM_COLL_SOTTOCOLL = ['usersCollab', 'usersSottocollab'];

export const STEPS = [
    {
        t_name: 'Fase 1',
        n_id: 1,
    },
    {
        t_name: 'Fase 2',
        n_id: 2,
    },
    {
        t_name: 'Fase 3',
        n_id: 3,
    },
    {
        t_name: 'Fase 4',
        n_id: 4,
    },
    {
        t_name: 'Fase 5',
        n_id: 5,
    },
];

export const MIME_TYPE_CONVERSION = {
    html: 'text/html',
    htm: 'text/html',
    shtml: 'text/html',
    css: 'text/css',
    xml: 'text/xml',
    gif: 'image/gif',
    jpeg: 'image/jpeg',
    jpg: 'image/jpeg',
    js: 'application/x-javascript',
    atom: 'application/atom+xml',
    rss: 'application/rss+xml',
    jar: 'application/java-archive',
    war: 'application/java-archive',
    ear: 'application/java-archive',
    hqx: 'application/mac-binhex40',
    doc: 'application/msword',
    docx: 'application/msword',
    pdf: 'application/pdf',
    ps: 'application/postscript',
    eps: 'application/postscript',
    ai: 'application/postscript',
    p7m: 'application/pdf',
    rtf: 'application/rtf',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.ms-excel',
    ppt: 'application/vnd.ms-powerpoint',
    pptx: 'application/vnd.ms-powerpoint',
    wmlc: 'application/vnd.wap.wmlc',
    kml: 'application/vnd.google-earth.kml+xml',
    kmz: 'application/vnd.google-earth.kmz',
    cco: 'application/x-cocoa',
    jardiff: 'application/x-java-archive-diff',
    jnlp: 'application/x-java-jnlp-file',
    run: 'application/x-makeself',
    pl: 'application/x-perl',
    pm: 'application/x-perl',
    prc: 'application/x-pilot',
    pdb: 'application/x-pilot',
    rar: 'application/x-rar-compressed',
    rpm: 'application/x-redhat-package-manager',
    sea: 'application/x-sea',
    swf: 'application/x-shockwave-flash',
    sit: 'application/x-stuffit',
    tcl: 'application/x-tcl',
    tk: 'application/x-tcl',
    der: 'application/x-x509-ca-cert',
    pem: 'application/x-x509-ca-cert',
    crt: 'application/x-x509-ca-cert',
    xpi: 'application/x-xpinstall',
    xhtml: 'application/xhtml+xml',
    zip: 'application/zip',
    sevenZ: 'application/x-7z-compressed', //7z
};

/*text/mathml                           mml,
text/plain                            txt,
text/vnd.sun.j2me.app-descriptor      jad,
text/vnd.wap.wml                      wml,
text/x-component                      htc,

image/png                             png,
image/tiff                            tif tiff,
image/vnd.wap.wbmp                    wbmp,
image/x-icon                          ico,
image/x-jng                           jng,
image/x-ms-bmp                        bmp,
image/svg+xml                         svg,
image/webp                            webp,

application/octet-stream              bin exe dll,
application/octet-stream              deb,
application/octet-stream              dmg,
application/octet-stream              eot,
application/octet-stream              iso img,
application/octet-stream              msi msp msm,

audio/midi                            mid midi kar,
audio/mpeg                            mp3,
audio/ogg                             ogg,
audio/x-realaudio                     ra,

video/3gpp                            3gpp 3gp,
video/mpeg                            mpeg mpg,
video/quicktime                       mov,
video/x-flv                           flv,
video/x-mng                           mng,
video/x-ms-asf                        asx asf,
video/x-ms-wmv                        wmv,
video/x-msvideo                       avi,
video/mp4                             m4v mp4,*/
