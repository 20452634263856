<div class="table-container">
	<div class="border mb-4 mt-4 dynamic-table" [ngClass]="{ 'd-none': dataSource?.data?.length === 0 && !configuration?.showWithNoData }">
		<div [formGroup]="form">
			<table mat-table [dataSource]="dataSource" matSort [matSortDisabled]="configuration?.disableSort" formArrayName="datas">
				<ng-container *ngFor="let col of configuration?.columns" [matColumnDef]="col.name">
					<ng-container *ngIf="col.name !== 'actions' && col.name !== 'checkable' && col.name !== 'radiable'">
						<th mat-header-cell *matHeaderCellDef mat-sort-header [class]="'text-center ' + col.cssClass">
							{{ configuration?.translationPrefix + col.name | translate }}
						</th>
						<td mat-cell *matCellDef="let row; let i = index" class="text-break" mat-row [class]="col.cssClass">
							<div [class]="col?.badgeStyleFn && col?.badgeStyleFn(row)" [ngClass]="{ 'badge badge-pill': col?.isBadge }">
								<i
									[class]="col.icon"
									aria-hidden="true"
									*ngIf="col.icon && (col.iconValidator ? col.iconValidator(row) : true)"
									tooltip="{{ renderTooltip(col.iconTooltip, row) | translate }}"
									(click)="configuration?.isSelectable ? $event.stopPropagation() : null; col.iconFunction ? col.iconFunction(row) : null"
								></i>
								<ng-container *ngIf="!col?.isInput">
									<span *ngIf="col?.viewTooltip && col?.viewTooltip(row)" tooltip="{{ colTooltip(row, col.name) | translate }}"
									 placement="bottom">
										{{ colRender(row, col.name) | translate }}	
									</span>	
								
									<span *ngIf="!col?.viewTooltip || !col?.viewTooltip(row)">
										{{ colRender(row, col.name) | translate }}										
									</span>							
								</ng-container>
								<ng-container *ngIf="col?.isInput" [formGroupName]="i">
									<div *ngIf="col?.inputType === 'DATE'" class="pe-1 py-1">
										<input
											type="text"
											class="form-control"
											placeholder="DD/MM/YYYY"
											bsDatepicker
											[minDate]="col?.minDateFn && col?.minDateFn(row, i)"
											[maxDate]="col?.maxDateFn && col?.maxDateFn(row, i)"
											[formControlName]="col?.modelName"
											[disabled]="col?.disabled"
											[bsConfig]="ls.bsConfig$ | async"
											style="cursor: pointer"
											placement="bottom"
										/>
										<span *ngIf="formRows.at(i).get(col?.modelName)?.touched && !formRows.at(i).get(col?.modelName)?.valid">
											<small class="text-danger">
												<span class="iconify-inline" data-icon="line-md:alert"></span>
												{{ printErrorMessages(formRows.at(i).get(col?.modelName).errors) | translate }}</small
											>
										</span>
									</div>
								</ng-container>
							</div>
						</td>
					</ng-container>
					<ng-container *ngIf="col.name === 'actions'">
						<th mat-header-cell *matHeaderCellDef [class]="col.cssClass">
							{{ configuration?.translationPrefix + col.name | translate }}
						</th>
						<td mat-cell *matCellDef="let row; let i = index" [class]="col.cssClass" style=" width: 10% !important;">
							<ng-template #buttonList>
								<ng-container *ngFor="let tButton of configuration?.tableButtons">
									<td mat-cell *ngIf="!tButton.isVisible || tButton?.isVisible(row)" class="cssBtn">
										<button										
											[class]="'btn ' + tButton.customClass"
											type="button"
											[disabled]="tButton?.isDisabled"
											(click)="configuration?.isSelectable ? $event.stopPropagation() : null; tButton.function(row, i)"
											tooltip="{{ tButton.tooltip | translate }}"
										>
											<i [class]="(configuration?.isMultiAction ? 'col-1 ps-0 ' : '') + tButton.icon" aria-hidden="true" *ngIf="tButton.icon"></i>
											{{ tButton.label | translate }}
										</button>
									</td>
								</ng-container>
							</ng-template>
							<ng-container *ngIf="configuration?.isMultiAction; else buttonList">
								<button mat-icon-button [matMenuTriggerFor]="menu" class="dynamic-table-menu-btn" (click)="$event.stopPropagation()">
									<i [class]="configuration.multiActionIcon ? configuration.multiActionIcon : 'fa fa-ellipsis-v'" aria-hidden="true"></i>
								</button>
								<mat-menu #menu="matMenu" class="dynamic-table-mat-menu">
									<ng-container *ngIf="!configuration?.isMultiAction; else buttonList"></ng-container>
								</mat-menu>
							</ng-container>
						</td>
					</ng-container>
					<ng-container *ngIf="col.name === 'checkable'">
						<th mat-header-cell *matHeaderCellDef>
							<mat-checkbox
								color="primary"
								(change)="$event ? toggleAllRows() : null"
								[checked]="checkedElements.hasValue() && isAllSelected()"
								[indeterminate]="checkedElements.hasValue() && !isAllSelected()"
								[disabled]="configuration?.disableCheck ? configuration?.disableCheck : false"
							>
							</mat-checkbox>
						</th>
						<td mat-cell *matCellDef="let row">
							<mat-checkbox
								color="primary"
								(click)="col.checkFunction ? ( configuration?.disableCheck ? configuration?.disableCheck : col.checkFunction(row)) : null"
								(change)="$event ? toggleRow(row) : null"
								[checked]="checkedElements.isSelected(row) || row.checked"
								[disabled]="configuration?.disableCheck ? configuration?.disableCheck : row.disabled"
							>
							</mat-checkbox>
						</td>
					</ng-container>
					<ng-container *ngIf="col.name === 'radiable'">
						<th mat-header-cell *matHeaderCellDef></th>
						<td mat-cell *matCellDef="let row">
							<mat-radio-button
								#radio
								color="primary"
								[checked]="radiedElement.isSelected(row)"
								(click)="$event ? checkRow(row, radio) : null; $event.stopPropagation()"
								[disabled]="configuration?.disableRadio ? configuration?.disableRadio(row) : null"
							>
							</mat-radio-button>
						</td>
					</ng-container>
					<tr class="mat-row" *matNoDataRow>
						<td class="mat-cell text-center" [attr.colspan]="configuration?.columns.length">
							{{ configuration?.noResultLabel | translate }}
						</td>
					</tr>
				</ng-container>
				<tr mat-header-row *matHeaderRowDef="colNames; sticky: configuration?.isStickyHeader"></tr>
				<tr
					mat-row
					*matRowDef="let row; columns: colNames"
					(click)="selectRow(row)"
					[ngClass]="{ 'selected-row-color': configuration?.isSelectable && selectedElement.isSelected(row), selectable: configuration?.isSelectable }"
				></tr>
			</table>
		</div>

		<mat-paginator *ngIf="configuration?.isPaginated" [length]="dataSource?.data?.length" [pageSizeOptions]="configuration?.pageSizeOptions" aria-label="Select page">
		</mat-paginator>
	</div>
</div>
