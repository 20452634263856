import { Injectable, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Observable, Subscriber } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Option, ConfirmDialogComponent, EditDialogComponent } from '../../components';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  bsModalRef: BsModalRef;
  messages: Array<string>;

  constructor(private bsModalService: BsModalService,
    private translate: TranslateService) { }

  confirm(message: string | Array<string>, title: string = 'dialog_title', options?: Option[]): Observable<Option> {
    if (!options) {
      options = [new Option('dialog_op_yes', 'dialog_op_yes'), new Option('dialog_op_no', 'dialog_op_no')];
    }
    const messages: Array<string> = message instanceof Array ? message : [message];
    this.translate.get([...messages, title, ...options.map(option => option.name)])
      .subscribe(res => {
        title = res[title];
        this.messages = messages.map(val => res[val]);
        options.forEach(option => option.name = res[option.name]);
        const initialState: any = {
          title: title,
          messages: messages,
          options: options,
          answer: new Option('', '')
        };
        this.bsModalRef = this.bsModalService.show(ConfirmDialogComponent, { initialState });
      });

    return new Observable<Option>(this.getConfirmSubscriber());
  }

  edit(message: string | Array<string>, title: string, objEditableStart: any, typeObject? :string, options?: Option[]): Observable<BsModalRef> {
    if (!options) {
      options = [new Option('dialog_op_yes', 'dialog_op_yes'), new Option('dialog_op_no', 'dialog_op_no')];
    }
    const messages: Array<string> = message instanceof Array ? message : [message];
    this.translate.get([...messages, title, ...options.map(option => option.name)])
      .subscribe(res => {
        title = res[title];
        this.messages = messages.map(val => res[val]);
        options.forEach(option => option.name = res[option.name]);
        const initialState: any = {
          title: title,
          messages: messages,
          typeObject: typeObject,
          options: options,
          objEditable: objEditableStart,
          answer: new Option('', '')
        };
        this.bsModalRef = this.bsModalService.show(EditDialogComponent, { initialState });
      });

    return new Observable<BsModalRef>(this.getEditSubscriber());
  }

  alert(message: string | Array<string>, title: string = 'Avviso', options?: Option[]): Observable<Option> {
    if (!options) {
      options = [new Option('ok', 'Ok')];
    }
    const messages: Array<string> = message instanceof Array ? message : [message];
    this.translate.get([...messages, title, ...options.map(option => option.name)])
      .subscribe(res => {
        title = res[title];
        this.messages = messages.map(val => res[val]);
        options.forEach(option => option.name = res[option.name]);
        const initialState: any = {
          title: title,
          messages: messages,
          options: options,
          answer: new Option('', '')
        };
        this.bsModalRef = this.bsModalService.show(ConfirmDialogComponent, {initialState });
      });
    return new Observable<Option>(this.getConfirmSubscriber());
  }

  private getEditSubscriber(): (this: Observable<BsModalRef>, subscriber: Subscriber<BsModalRef>) => void {
    return (observer) => {
      const subscription = this.bsModalService.onHidden.subscribe(() => {
        observer.next(this.bsModalRef);
        observer.complete();
      });

      return {
        unsubscribe() {
          subscription.unsubscribe();
        }
      };
    };
  }


  private getConfirmSubscriber(): (this: Observable<Option>, subscriber: Subscriber<Option>) => void {
    return (observer) => {
      const subscription = this.bsModalService.onHidden.subscribe(() => {
        observer.next(this.bsModalRef.content.answer);
        observer.complete();
      });

      return {
        unsubscribe() {
          subscription.unsubscribe();
        }
      };
    };
  }

  public show(content: string | TemplateRef<any> | any, config?: ModalOptions): BsModalRef {
    return this.bsModalService.show(content, config);
  }

  public closeAllModals() {
    for (let i = 1; i <= this.bsModalService.getModalsCount(); i++) {
      this.bsModalService.hide(i);
    }
  }

}
