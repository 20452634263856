import { Injectable, EventEmitter } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TimeoutModalComponent } from '../../components';
import { Keepalive } from '@ng-idle/keepalive';
import { Idle, DEFAULT_INTERRUPTSOURCES, InterruptSource } from '@ng-idle/core';

/**
 * @description service che si occupa della gestione dell'inattività dell'utente.
 */
@Injectable({
  providedIn: 'root'
})
export class IdleService {

  private bsModalRef: BsModalRef;
  private inactive_timeout: number;

  constructor(
    private bsModalService: BsModalService,
    private idle: Idle,
    private keepalive: Keepalive) { }

  /**
   * Il metodo esegue il setup per monitorare l'inattività dell'utente.
   *
   * @param { number } minutes definisce il numero di minuti di inattività dopo i quali viene notificato il timeout.
   *
   * @param { number } idleTimePercentage definisce la percentuale di tempo, calcolata su minutes, dopo la quale è notificato che
   * l'utente non sta eseguendo operazioni.
   *
   * @param { number } keepaliveInterval numero di secondi che definisce l'intervallo tra due ping successivi.
   *
   * @param { InterruptSource[] } interrupts array che rappresenta gli 'oggetti' su cui monitorare l'inattività, se al metodo non è
   * fornito alcun valore, sarà monitorata l'inattività dell'utente relativa all'utilizzo di mouse e tastiera
   *
   * @returns {
   *  { onTimeoutWarning: EventEmitter<number>, onTimeout: EventEmitter<number>, onPing: EventEmitter<any>}
   * } L' oggetto ha seguenti attributi:
   *   onTimeoutWarning: notifica i secondi che mancano al timeout per inattività, le notifiche partono dopo che il tempo relativo
   *   all'idleTimePercentage è passato.
   *
   *   onTimeout: notifica che il tempo definito in minutes è terminato.
   *
   *   onPing: notifica la ripresa dell'attività dell'utente.
   *
   * @example
   *  this.start(30, 0.8, 1);
   *  30 = numero di minuti dopo il quale è timeout
   *  0.8 = percentuale dopo la quale è notificata l&#39;inattività dell utente
   *  1 = intervallo di ping di un secondo
   *
   *  onTimeoutWarning emetterà i valori dopo 30 * 0.8 minuti.
   *  onTimeout emetterà i valori dopo 30 minuti.
   *  onPing emetterà i valori alla ripresa dell attività dell&#39;utente che sarà pingata ogni secondo
   */
  public start(
    minutes: number, idleTimePercentage: number, keepaliveInterval: number, interrupts: InterruptSource[] = DEFAULT_INTERRUPTSOURCES
  ): { onTimeoutWarning: EventEmitter<number>, onTimeout: EventEmitter<number>, onPing: EventEmitter<any> } {
    this.inactive_timeout = minutes * 60;

    const idleTime = this.inactive_timeout * idleTimePercentage;
    const timeoutTime = this.inactive_timeout - idleTime;
    this.idle.setIdle(idleTime);
    this.idle.setTimeout(timeoutTime);
    this.idle.setInterrupts(interrupts);
    this.keepalive.interval(keepaliveInterval);
    this.idle.watch();

    return { onTimeoutWarning: this.idle.onTimeoutWarning, onTimeout: this.idle.onTimeout, onPing: this.keepalive.onPing }
  }

  /**
   * Il metodo termina l'attività di monitoraggio dell'inattività dell'utente, chiudendo eventualmente la modale con il timer.
   */
  public stop(): void {
    this.idle.stop();
    this.hideTimeoutModal();
  }

  /**
   * Il metodo apre una finestra modale che mostra il valore numerico passato nel countdown, se la modale è già aperta è aggiornato solo
   * il valore del countdown.
   * @param {number} countdown valore numerico utilizzabile come countdown
   */
  public showTimeoutModal(countdown: number) {
    if (!this.bsModalRef) {
      const config = {
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: true,
        initialState: { countdown: countdown }
      };
      this.bsModalRef = this.bsModalService.show(TimeoutModalComponent, config);
      return;
    }
    this.bsModalRef.content.countdown = countdown;
  }

  /**
   * Il metodo esegue la chiusura della finestra modale relativa al countdown.
   */
  public hideTimeoutModal() {
    if (!this.bsModalRef) {
      return;
    }
    this.bsModalRef.hide();
    this.bsModalRef = null;
  }
}
