<c-dropdown alignment="end" variant="nav-item">
  <button cButton color="" [caret]="false" cDropdownToggle class="py-0 border-0">
    <span class="iconify" data-icon="fa:globe"></span>
  </button>
  <ul cDropdownMenu class="p-0 w-auto">
    <li>
      <h4 cDropdownHeader class="bg-light fw-semibold text-center m-0"><strong>{{ menuLabel }}</strong></h4>
    </li>
    <li class="" *ngFor="let locale of localeService.supportedLocales">
      <button class="dropdown-item px-5 py-2" (click)="switchLocale(locale.id)">
        <span class="iconify me-3" [attr.data-icon]="locale.icon"></span>{{ locale.description }}
      </button>
    </li>
  </ul>
</c-dropdown>
