import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { RisultatoDTO, TokenResponse } from '../../models';

import { AuthService } from '../../services/auth/auth.service';
import { LOCALEKEY } from '../../services/locale/locale.service';
import { ToastService } from '../../services/toast/toast.service';

export const http_header = 'Authorization';
export const http_language_header = 'message-language';

@Injectable({
	providedIn: 'root',
})
export class HttpTokenInterceptor implements HttpInterceptor {
	lngDefault: string = 'it';
	private isRefreshing = false;
	private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	constructor(
		private authService: AuthService,
		private router: Router,
		private toastService: ToastService,
	) { }


	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(this.addHeaderLanguage(request)).pipe(
			tap((event: HttpEvent<any>) => {
				if (event instanceof HttpResponse) {
					let token = this.authService.getToken();
					if (token) {
						this.checkExpireDateToken();
					} else {
						this.router.navigate([environment.loginPage]);
					}
				}
				return event;
			}),
			catchError(error => {
				if (error instanceof HttpErrorResponse && error.status === 401) {
					return this.handle401Error(request, next);
				} else {
					this.toastService.showError('Il servizio non è al momento disponibile');
				}
				return throwError(error);
			}));
	}

	handle401Error(request: HttpRequest<any>, next: HttpHandler) {
		if (!this.isRefreshing) {
			this.isRefreshing = true;
			this.refreshTokenSubject.next(null);
			return this.authService.refreshToken().pipe(
				switchMap((result: RisultatoDTO<TokenResponse>) => {
					this.isRefreshing = false;
					this.refreshTokenSubject.next(result.data.access_token);
					return next.handle(this.addAuthenticationToken(this.addHeaderLanguage(request), result.data.access_token));
				}));
		} else {
			return this.refreshTokenSubject.pipe(
				filter(token => token != null),
				take(1),
				switchMap(token => {
					return next.handle(this.addAuthenticationToken(this.addHeaderLanguage(request), token));
				}));
		}
	}

	checkExpireDateToken() {
		let data_corrente = new Date();
		let expires_date = new Date(this.authService.getExpiresToken());
		if (expires_date.getTime() < data_corrente.getTime()) {
			return;
			//GENERAZIONE TOKEN 1 minuti PRIMA
		} else if ((expires_date.getTime() - 60000) < data_corrente.getTime()) {
			this.generateToken();
		} else {
			return;
		}
	}

	generateToken() {
		this.authService.generateToken().subscribe(
			(result) => {
				if (result.code >= 200 && result.code < 300 && result.success) {
					this.authService.storeToken(result.data.access_token);
					this.authService.storeExpiresToken(result.data.expires_at);
				} else {
					this.router.navigate([environment.loginPage]);
				}
			},
			(error) => {
				console.log('error');
				this.router.navigate([environment.loginPage]);
			}
		);
	}
	
	addAuthenticationToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
		return request.clone({
			headers: request.headers.set(http_header, 'Bearer ' + token)
		});
	}

	addHeaderLanguage(request: HttpRequest<any>): HttpRequest<any> {
		let language: string = localStorage.getItem(LOCALEKEY) ? localStorage.getItem(LOCALEKEY).toLocaleUpperCase() : this.lngDefault.toLocaleUpperCase();
		let headersComplete = request.headers.set(http_language_header, language);
		return request.clone({
			headers: headersComplete,
		});
	}

}
