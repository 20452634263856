<div class="h-100 d-flex justify-content-center">
  <div class="container align-self-center">
    <div class="row justify-content-center ">
      <div class="col-sm-12 text-center">
        <h2 class="display-1 text-primary"><i class="fa fa-frown-o" aria-hidden="true"></i></h2>
      </div>
      <div class="col-sm-12 text-center">
        <h1 class="display-1 text-primary"> {{'page404.title' | translate}}</h1>
      </div>
      <div class="col-sm-12 text-center">
        <h4>{{'page404.oops_looks_like_you_re_lost' | translate}}</h4>
      </div>
      <div class="col-sm-12 text-center">
        <p class="text-muted">{{'page404.the_page_you_are_looking_for_was_not_found' | translate}}</p>
      </div>
    </div>
  </div>
</div>
