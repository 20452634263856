import { DatePipe } from "@angular/common";
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Optional, Output, Self, SimpleChanges, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DateAdapter } from "@angular/material/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { UtilityService } from "../../../../core/services";

@Component({
	selector: 'app-mat-datapicker',
	templateUrl: './mat-datapicker.component.html',
	styleUrls: ['./mat-datapicker.component.scss'],
})
export class MatDatapickerComponent implements OnInit, AfterViewInit, OnChanges {
	@Output() onChangeValueDate: EventEmitter<any> = new EventEmitter();
	@Input() dateResetInput :string;

	form: FormGroup =  this.fb.group({ dateInput: null });

	constructor(private dateAdapter: DateAdapter<Date>, 
		        private fb: FormBuilder,
				private datePipe: DatePipe,
				private utilityService: UtilityService) {	
		this.dateAdapter.setLocale('it-IT');		
	}

	ngOnInit(): void {	
	}

	ngAfterViewInit(): void {
	}

    ngOnChanges(changes: SimpleChanges): void {
		if(!this.dateResetInput) this.form.get('dateInput').setValue(null); 
	}

	onKeyupValue(event :MatDatepickerInputEvent<any>){
		let finalDate = event.target.value;			
		/*let parts_of_date :string[] = finalDate.split("/");
		let date = parts_of_date.length === 3 && parts_of_date[2].length === 4 && parts_of_date[1].length == 2 && parts_of_date[0].length == 2 ?  
		new Date(this.toMaskFormat(parts_of_date[2]), this.toMaskFormat(String(Number(parts_of_date[1])-1)), this.toMaskFormat(parts_of_date[0])) : null;
		if(date) {		
				
			//finalDate = date.setDate(date.getDate() + 1);
			//finalDate = this.datePipe.transform(finalDate, 'dd/MM/YYYY');
			
			//finalDate = moment(date).toISOString();
		} 	*/
		this.onChangeValueDate.emit(finalDate);
	}

	onChangeValue(){
		let finalDate;			
		let dateProgress = new Date(this.form.get('dateInput').value);
		if(this.dateAdapter.isValid(dateProgress)) {
			//finalDate = dateProgress.setDate(dateProgress.getDate() + 1);
			//finalDate = dateProgress.toISOString();

			//finalDate = (new Date(finalDate)); 
			finalDate = this.datePipe.transform(dateProgress, 'dd/MM/YYYY');
		}
		this.onChangeValueDate.emit(finalDate);		
	}
	
	clearDate(event) {
		event.stopPropagation();
		this.onChangeValueDate.emit(null);	
	}
}
