import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { nMaxLenghtInput } from '../../../../core/utility';
import { ProductDataFormModel } from './product-data-form.model';

@Component({
  selector: 'app-product-data-form',
  templateUrl: './product-data-form.component.html',
  styleUrls: ['./product-data-form.component.css']
})
export class ProductDataFormComponent implements OnInit {

  @Input() bOpenCreate: boolean;
  @Input() bOpenDetail: boolean;
  @Input() bOpenEdit: boolean;
  @Input() notExistCorrectRelationProdDocum: boolean;  
  @Output() onSaveProduct: EventEmitter<void> = new EventEmitter();
  @Output() onDeleteProduct: EventEmitter<void> = new EventEmitter();
  @Output() onDeleteInsertProduct: EventEmitter<void> = new EventEmitter();
  @Output() onEnableUpdateProduct: EventEmitter<void> = new EventEmitter();
  
  form: FormGroup; 
  nMaxLenghtInput: number = nMaxLenghtInput;

  constructor( private formBuilder: RxFormBuilder,
               private cdr: ChangeDetectorRef
             ) { }

  ngOnInit() {
  }

  createForm(cdfm: ProductDataFormModel) {
    this.form = this.formBuilder.formGroup(cdfm);
  }

  disableForm() {
    if(this.bOpenDetail){  
      this.form.disable();
      this.form.markAsPristine();
      this.form.markAsUntouched();
      this.form.updateValueAndValidity();
    }
  }

  ctrlValidity(field :string){
    return  this.form.get(field).touched && !this.form.get(field).valid;
  }   

  getDynamicTitle() :string{
    return "product." + ( this.bOpenCreate ? 'create' : ( this.bOpenEdit ? 'edit' : 'detail' ) )+ ".title";
  }

  save() {
    this.onSaveProduct.emit();
  } 

  deleteProduct() {
    this.onDeleteProduct.emit();
  }

  deleteInsert(){
    this.onDeleteInsertProduct.emit();
  }

  enabledEdit() {
    this.bOpenCreate = false;
    this.bOpenDetail = false;
    this.bOpenEdit = true;
    this.form.enable();
    this.cdr.detectChanges();
    this.onEnableUpdateProduct.emit();
  }

  getDisableBtnSave() :boolean{    
    return !this.form.valid || this.notExistCorrectRelationProdDocum;
  }

  set t_name(t_name: string) {
    this.form.get('t_name').setValue(t_name);
  }

  get t_name() {
    return this.form.get('t_name').value;
  }

  set t_description(t_description: string) {
    this.form.get('t_description').setValue(t_description);
  }

  get t_description() {
    return this.form.get('t_description').value;
  }

}
