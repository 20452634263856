import { minLength, prop, required } from "@rxweb/reactive-form-validators";
import { ERROR_MESSAGES } from "../../utility";

export class DocumentationCreateFormModel {

    @prop()
    n_id: string;
    
	@required({ message: ERROR_MESSAGES.create.t_name.required.message })
	@minLength({ value: ERROR_MESSAGES.create.t_name.minLength.value, message: ERROR_MESSAGES.create.t_name.minLength.message })
	t_name :string;

    @prop()
	t_description :string;

    @prop()
    n_step: string;

    constructor() {
        this.n_id = null;
        this.t_name = null;
        this.t_description = null; 
        this.n_step = null;    
    }
}
