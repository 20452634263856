import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

import { MatFiltrableListConfiguration } from '../../models';

@Component({
	selector: "tifinanzio-mat-filtrable-list",
	templateUrl: "./mat-filtrable-list.component.html",
	styleUrls: ["./mat-filtrable-list.component.scss"],
})
export class MatFiltrableListComponent implements OnInit, OnChanges {
	searchController: FormControl = new FormControl("");
	@Input() configuration: MatFiltrableListConfiguration;
	@Input() renderList: any[];
	@Output() onTableCheck: EventEmitter<any> = new EventEmitter();
	filteredRenderList: any[];
	checkedElements = new SelectionModel<any>(true, []);
	selectedElement = new SelectionModel<any>(false, null);
	@Output() onSelectElement: EventEmitter<any> = new EventEmitter();

	constructor() {
		this.filteredRenderList = this.renderList;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.configuration) {
			if (this.configuration?.filterDisabled) {
				this.searchController.disable();
			} else {
				this.searchController.enable();
			}
		}
		this.filteredRenderList = this.renderList;
		this.filter();
	}

	ngOnInit() {
		this.searchController.valueChanges.subscribe((res) => {
			this.filter();
		});
	}

	filter() {
		if (this.renderList && this.renderList.length > 0 && this.configuration?.renderName) {
			this.filteredRenderList = this.renderList.filter((rl) => {
				if (!this.searchFilter) {
					return true;
				}
				return rl[this.configuration.renderName]?.toLowerCase().includes(this.searchFilter?.toLowerCase());
			});
		}
	}

	get searchFilter(): string {
		return this.searchController.value;
	}

	checkElement(element: any) {
		this.checkedElements.toggle(element);
		this.onTableCheck.emit(element);
	}

	selectElement(element: any) {
		if (this.configuration.isSelectable && !this.configuration.disableSelect) {
			if (!this.selectedElement.isSelected(element)) {
				this.selectedElement.clear();
				this.selectedElement.toggle(element);
				this.onSelectElement.emit(element);
			} else {
				this.selectedElement.clear();
				this.onSelectElement.emit(null);
			}
		}
	}

	renderName(row: any) {
		if (this.configuration && this.configuration.useRenderNamePipe) {
			return this.configuration.useRenderNamePipe(row);
		}
		return row[this.configuration.renderName];
	}
}
