import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslationAttributes } from '../../models';

export class Option {
  constructor(public key: string, public name: string, public type: string = 'button') { }
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html'
})
export class ConfirmDialogComponent implements OnInit {
  title: string;
  messages: string;
  options: Array<Option>;
  answer: Option;
  type: string;

  constructor(public bsModalRef: BsModalRef) {
  }

  respond(answer?: Option) {
    this.answer = answer ? answer : new Option(null, null);
    this.bsModalRef.hide();
  }

  ngOnInit() { }

}
