<div [formGroup]="form" *ngIf="form" class="row d-block m-0 p-0">
  <div class="modal-content">     
    <div class="modal-header">
      <h4 class="modal-title pull-left fw-bold">{{'messages_service.'+title | translate}}</h4>
      <button type="button" class="btn btn-link btn-link--reset btn-sm text-decoration-none align-self-start"
        aria-label="Close" (click)="respond()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>    
    <div class="modal-body" style="word-wrap: break-word;">
      <div *ngFor="let message of messages">
        {{message | translate}}
      </div>
      <div class="row mt-3" *ngIf="isDocumentation">
        <div class="col-md-12">            
            <div class="form-group">                   
                <label for="t_name" class="form-label"> {{ 'product.detail.form.t_name'  | translate}} *</label>
                <input type="text" class="form-control" formControlName="t_name" id="t_name" maxlength="{{nMaxLenghtInput}}">
                <mat-error *ngIf="ctrlValidity('t_name')">
                    <small *ngFor="let error of form.get('t_name')['errorMessages']">
                        {{ error | translate }}
                    </small>
                </mat-error>                                  
            </div>
        </div> 
        <div class="col-md-12">            
            <div class="form-group">
                <label for="t_description" class="form-label"> {{'product.detail.form.t_description' | translate}}</label>
                <input type="text" class="form-control" formControlName="t_description" id="t_description" maxlength="{{nMaxLenghtInput}}">                               
            </div>
        </div> 
        <div class="col-md-12" *ngIf="!isEdit">
          <div class="custom-ng-select">
            <label for="n_step" class="form-label"> {{'product.detail.form.n_step' | translate}} </label>
            <ng-select appearance="outline" [items]="stages" placeholder="{{'product.search.form.n_step_placeholder'| translate}}"
                bindLabel="t_name" bindValue="n_id" formControlName="n_step">
                <ng-template ng-option-tmp let-item="item">
                <div title="{{item.t_description }}">{{item.t_name}} </div>
                </ng-template>
            </ng-select>
        </div>  
      </div>   
      <div class="row pt-4">
        <div class="col-sm-12">
          {{ 'messages.required_fields' | translate }}
        </div>
      </div> 
    </div>
    <div class="modal-footer">
      <ng-container *ngIf="options.length">
        <ng-container *ngFor="let option of options">
          <ng-container [ngSwitch]="option.type">
            <button *ngSwitchCase="'button'" type="button" class="btn btn-primary"
            (click)="respond(option)">{{'messages_service.'+option.name | translate}}</button>
            <button *ngSwitchCase="'link'" type="button" class="btn btn-link"
            (click)="respond(option)">{{'messages_service.'+option.name | translate}}</button>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
  