<div class="container-fluid">
  <div class="row justify-content-center align-items-center vh-100">
    <div class="col-12 col-lg-6 row bg-light align-items-center vh-100 justify-content-center">
      <main class="main d-flex align-items-center">
        <div class="container">
          <div class="row">
            <div class="col-md-7 mx-auto">
              <form [formGroup]="form">
                <div class="p-4 d-block d-lg-none text-center"><img class="img-fluid" width="300px"
                    src="../../../../assets/images/ti-finanzio.png"></div>
                <h1 class="fw-bold">{{'login.title'|translate}}</h1>
                <p class="text-muted">{{'login.info'|translate}}</p>
                <label>{{'login.t_email'|translate}}</label>
                <div class="input-group">
                  <input type="text" class="no-focus form-control bg-transparent" placeholder="{{'login.t_email_placeholder'|translate}}"
                    autocomplete="t_email" formControlName="t_email" (keyup)="toLowerCase()">
                </div>
                <div class="input-group mb-1 err-min-space">
                  <span *ngIf="form.controls.t_email.touched && !form.controls.t_email.valid ">
                    <small class="text-danger"
                      *ngFor="let error of form.controls.t_email['errorMessages']">{{error|translate}}</small>
                  </span>
                </div>
                <label>{{'login.t_password'|translate}}</label>
                <div
                  class="input-group justify-content-center align-items-center p-0 form-control bg-transparent rounded-1 {{!valid_password?'ng-invalid ng-touched':''}} {{dynamicFocusClass}}">
                  <input style="border:0!important" autocomplete="current-password" [type]="password_type"
                    class="no-focus form-control border-0 bg-transparent" placeholder="{{'login.t_password_placeholder'|translate}}"
                    formControlName="t_password" (focusout)="deFocusContainer()" (focus)="focusContainer()">
                  <div class="input-group-append position-relative end-0 h-100 eye-z-index" style="cursor: pointer;"
                    (click)="showHidePassword()">
                    <span class="border-0 input-group-text h-100 bg-transparent">
                      <i [class]="password_icon" aria-hidden="true"></i>
                    </span>
                  </div>
                </div>
                <div class="input-group mb-2 err-min-space">
                  <span *ngIf="form.controls.t_password.touched && !form.controls.t_password.valid ">
                    <small class="text-danger"
                      *ngFor="let error of form.controls.t_password['errorMessages']">{{error|translate}}</small>
                  </span>
                </div>
                <div class="row">
                  <div class="col-12 d-grid">
                    <button type="submit" class="btn btn-primary px-4" [disabled]="form.invalid"
                      (click)="login()">{{'login.buttons.login'|translate}}</button>
                  </div>
                  <div class="col-6 d-flex align-items-center text-left">
                    <label class="forgotPW">{{'login.buttons.forgot_button_label'|translate}}</label>
                  </div>
                  <div class="col-6 d-flex justify-content-end">
                    <button type="button" class="btn btn-link px-0" (click)="passwordDimenticataPage()">{{'login.buttons.forgot_button_title'|translate}}</button>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </main>
    </div>
    <div class="col-lg-6 justify-content-center d-none d-sm-block">
      <div class="text-center"><img class="img-fluid" width="300px" src="../../../../assets/images/ti-finanzio.png">
      </div>
    </div>
  </div>
</div>