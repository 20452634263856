import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { PositionDetailResponse, PositionSearchRequest,RisultatoDTO,ResponsePaginatedDTO} from '../../models';

export const GDP = "position/1.0/getDetailsPosition";
export const GLP = "position/1.0/getListPosition";

@Injectable({
    providedIn: 'root'
})
export class PositionService {

    constructor(private httpClient: HttpClient) { }
    
    getDetailsPosition(n_id_position:number): Observable<RisultatoDTO<PositionDetailResponse>> {
      let param = "?n_id=" + n_id_position;
      return this.httpClient.get<RisultatoDTO<PositionDetailResponse>>(`${environment.apiBaseUrl}${environment.pathTifinanzio}${GDP}${param}`);
    }

    getListPosition(positionSearchRequest:PositionSearchRequest):Observable<RisultatoDTO<ResponsePaginatedDTO<PositionSearchRequest>>> {
      return this.httpClient.post<RisultatoDTO<ResponsePaginatedDTO<PositionSearchRequest>>>(`${environment.apiBaseUrl}${environment.pathTifinanzio}${GLP}`, positionSearchRequest);
    }
    
}