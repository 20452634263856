export const regex_form_control = {
    t_number_regex: /^[0-9]+$/,
    t_cap_regex: /^[0-9][0-9][0-9][1-9][0-9]$/,
    t_partita_iva_regex: /^[0-9]{11}$/,
    t_alphaNumeric_regex: /^[A-Za-z0-9]+$/,
    t_password_regex: /^\S*$/,
    t_pec_regex:
        /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/,
    t_email_regex:
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    t_fiscal_code_regex:
        /^(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i,
    t_date_regex:
        /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g,
    t_date_format:
        /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/,
};

export const messages = {
    t_username: {
        required: 'User.create.form.errors.t_username.required',
        pattern: 'User.create.form.errors.t_username.pattern',
    },
    t_password: {
        required: 'User.create.form.errors.t_password.required',
        pattern: 'User.create.form.errors.t_password.pattern',
    },
    t_password_confirm: {
        required: 'User.create.form.errors.t_password_confirm.required',
        pattern: 'User.create.form.errors.t_password_confirm.pattern',
    },
    t_password_check: {
        not_equals: 'messages.errors.t_password_check.not_equals',
    },
    t_surname: {
        required: 'User.create.form.errors.t_surname.required',
    },
    t_name: {
        required: 'User.create.form.errors.t_name.required',
    },
    t_fiscal_code: {
        required: 'User.create.form.errors.t_fiscal_code.required',
        pattern: 'User.create.form.errors.t_fiscal_code.pattern',
        invalid_digit: 'messages.errors.t_fiscal_code.invalid_digit',
    },
    t_id_parent: {
        required: 'User.create.form.errors.t_id_parent.required',
    },
    n_id_product: {
        required: 'messages.errors.n_id_product.required',
    },
    n_amount: {
        required: 'messages.errors.n_amount.required',
        pattern: 'messages.errors.n_amount.pattern',
        min: 'messages.errors.n_amount.min',
        max: 'messages.errors.n_amount.max',
    },
    n_duration: {
        required: 'messages.errors.n_duration.required',
        pattern: 'messages.errors.n_duration.pattern',
        min: 'messages.errors.n_duration.min',
        max: 'messages.errors.n_duration.max',
    },
    n_id_customer: {
        required: 'messages.errors.n_id_customer.required',
        pattern: 'messages.errors.n_id_customer.pattern',
    },
    t_id_user: {
        required: 'messages.errors.t_id_user.required',
    },
    n_id_documentation: {
        required: 'messages.errors.n_id_documentation.required',
    },
    d_signing_date: {
        required: 'messages.errors.d_signing_date.required',
    },
    n_pre_depreciation: {
        min: 'messages.errors.n_pre_deprecation.min',
        max: 'messages.errors.n_pre_deprecation.max',
    },
    n_bank_amount: {
        min: 'messages.errors.n_bank_amount.min',
        max: 'messages.errors.n_bank_amount.max',
    },
    n_bank_duration: {
        min: 'messages.errors.n_bank_duration.min',
        max: 'messages.errors.n_bank_duration.max',
    },
    n_commission: {
        min: 'messages.errors.n_commission.min',
        max: 'messages.errors.n_commission.max',
    },
    t_survey_detection: {
        required: 'messages.errors.t_survey_detection.required',
    },
    t_membership_number: {
        required: 'messages.errors.t_membership_number.required',
    },
    d_year: {
        required: 'messages.errors.d_year.required',
    },
    n_progressive_year: {
        required: 'messages.errors.n_progressive_year.required',
        min: 'messages.errors.n_progressive_year.min',
    },
    t_company_name: {
        required: 'messages.errors.t_company_name.required',
    },
    t_vat_number: {
        required: 'messages.errors.t_vat_number.required',
        pattern: 'messages.errors.t_vat_number.pattern',
        minLength: { value: 11, message: 'messages.errors.t_vat_number.minLength' },
        invalid_digit: 'messages.errors.t_vat_number.invalid_digit',
    },
    t_entry_number: {
        required: 'messages.errors.t_entry_number.required',
    },
    t_address: {
        required: 'messages.errors.t_address.required',
    },
    t_street_number: {
        required: 'messages.errors.t_street_number.required',
        min: 'messages.errors.t_street_number.min',
    },
    t_city: {
        required: 'messages.errors.t_city.required',
    },
    t_zip_code: {
        required: 'messages.errors.t_zip_code.required',
        pattern: 'messages.errors.t_zip_code.pattern',
    },
    t_province: {
        required: 'messages.errors.t_province.required',
    },
    t_region: {
        required: 'messages.errors.t_region.required',
    },
    t_responsible: {
        required: 'messages.errors.t_responsible.required',
    },
    t_main_location: {
        required: 'messages.errors.t_main_location.required',
    },
    n_id_registry: {
        required: 'messages.errors.n_id_registry.required',
    },
    n_id_bank: {
        required: 'messages.errors.n_id_bank.required',
    },
    t_convention: {
        required: 'messages.errors.t_convention.required',
    },
    t_management_mode: {
        required: 'messages.errors.t_management_mode.required',
    },
    t_bank_name: {
        required: 'messages.errors.t_bank_name.required',
    },
};
