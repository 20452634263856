import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { Product } from '../../../../core/models';
import { ProductSearchFormModel } from './product-search-form.model';

@Component({
  selector: 'app-product-search-form',
  templateUrl: './product-search-form.component.html',
  styleUrls: ['./product-search-form.component.css']
})
export class ProductSearchFormComponent implements OnInit { 
  @Output() onSelectElement: EventEmitter<any> = new EventEmitter();
  @Output() onOpenCreate: EventEmitter<any> = new EventEmitter();
  @Output() onInputValueProduct: EventEmitter<any> = new EventEmitter();  
  @Input() products: Product[];
  @Input() bDisabledNewProductBtn: boolean;

  form: FormGroup;
  
  constructor( private formBuilder: RxFormBuilder ) { }

  ngOnInit() {
  }

  createForm(psfm: ProductSearchFormModel) {
    this.form = this.formBuilder.formGroup(psfm);
  }

  resetForm(){
    this.form.reset();
    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.form.updateValueAndValidity();
  }

  disableForm(){
    this.form.disable();
    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.form.updateValueAndValidity();
  }

  openCreate(){
    this.onOpenCreate.emit();
  }

  onChangeValue(){
    this.onSelectElement.emit();
  } 

  filterProduct(event){
    this.onInputValueProduct.emit(event);
  }
}
