import { TranslateLoader, TranslateModuleConfig } from "@ngx-translate/core";
import { HttpClient } from '@angular/common/http';
import { HttpLoaderFactory } from './factories';

// Translate
export const translateModuleConfig: TranslateModuleConfig = {
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient]
    },
    useDefaultLang: true
  };