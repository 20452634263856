import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ForgotPasswordFormModel } from './forgot-password-form.model';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { Router } from '@angular/router';
import { ToastService } from '../../services/toast/toast.service';
import { AuthService } from '../../services/auth/auth.service';
import { sha512 } from 'js-sha512';
import { environment } from '../../../../environments/environment';
import { RecoveryPasswordRequest } from '../../models';


@Component({
  selector: 'tifinanzio-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  form: FormGroup;
  //recupera_password_request: PasswordRecovery;
  recupera_password_request: any;
  password_generata: string;

  constructor(
    private formBuilder: RxFormBuilder,
    private router: Router,
    private toastService: ToastService,
    private authService: AuthService,
  ) {
    this.creaForm(new ForgotPasswordFormModel);
  }

  creaForm(fpfm: ForgotPasswordFormModel) {
    this.form = this.formBuilder.formGroup(fpfm);
  }

  set t_email(t_email: string) {
    this.form.get('t_email').setValue(t_email);
  }

  get t_email() {
    return this.form.get('t_email').value;
  }

  ngOnInit(): void {
  }

  loginPage() {
    this.router.navigate(['/login']);
  }

  passwordRecovery() {
    if (this.form.valid) {
      let email = this.t_email.trim();
      this.t_email = email;
      this.authService.generateToken().subscribe((result) => {
        this.authService.storeToken(result.data.access_token);
        this.authService.storeExpiresToken(result.data.expires_at);
        this.authService.recoveryPassword(this.costruisciRequest()).subscribe(result => {
          if (result.code >= 200 && result.code < 300) {
            if (result.success) {
              this.toastService.showSuccess(result.descrizione);
              this.router.navigate([environment.loginPage]);
            } else {
              this.toastService.showError(result.descrizione);
            }
          } else {
            this.toastService.showError(result.descrizione);
          }
        });
      });
    }
  }

  costruisciRequest(): RecoveryPasswordRequest {
    var generator = require('generate-password-browser');
    this.password_generata = generator.generate({
      length: 8,
      numbers: true
    })
    return this.recupera_password_request = {
      username: this.t_email,
      password_blurred: sha512(this.password_generata),
      password: this.password_generata
    };
  }


}
