<div class="d-block h-100 mat-filter-list-container rounded">
  <div>
    <div class="row px-4 pb-2">
      <mat-form-field class="w-100 p-0 m-0" appearance="standard">
        <i matPrefix
          class="text-black-50 pe-2 {{ !configuration?.filterIcon ? 'fa fa-search' : configuration?.filterIcon }}"
          aria-hidden="true"></i>
        <input type="text" [formControl]="searchController" matInput
          [placeholder]="configuration?.filterPlaceholder | translate" />
      </mat-form-field>
    </div>
  </div>
  <div class="list-container overflow-auto">
    <div class="row m-0 w-100">
      <div class="row w-100 list-element m-0 p-0" [ngClass]="{
				'list-element-hoverable': !configuration.disableSelect && configuration?.isSelectable,
				'list-element-selected': !configuration.disableSelect && configuration?.isSelectable && selectedElement.isSelected(element)
			}" *ngFor="let element of filteredRenderList" (click)="$event.stopPropagation(); selectElement(element)">
        <div class="col-1  col-1 p-0 text-center check-container" *ngIf="configuration?.isCheckable">
          <mat-checkbox
            [disabled]="configuration?.disableCheck || (configuration?.disableCheckIfChecked && checkedElements.isSelected(element))"
            (click)="$event.stopPropagation()" (change)="$event ? checkElement(element) : null"
            [checked]="checkedElements.isSelected(element)" class="pt-1" color="primary">
          </mat-checkbox>
        </div>
        <div class="col" [ngClass]="{ 'p-0': configuration?.isCheckable }">
          <p class="m-0 px-1 py-1">{{renderName(element)}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
