<div class="container-fluid">
  <div class="row justify-content-center align-items-center vh-100">
    <div class="col-12 col-lg-6 row bg-light align-items-center vh-100 justify-content-center">
      <main class="main d-flex align-items-center">
        <div class="container">
          <div class="row">
            <div class="col-md-7 mx-auto">
              <form [formGroup]="form">
                <div class="p-4 d-block d-lg-none text-center"><img class="img-fluid" width="300px"
                    src="../../../../assets/images/ti-finanzio.png"></div>
                <h1 class="fw-bold">{{'forgot_password.title'|translate}}</h1>
                <p class="text-muted">{{'forgot_password.info'|translate}}</p>
                <label>{{'forgot_password.t_email'|translate}}</label>
                <div class="input-group">
                  <input type="text" class="no-focus form-control bg-transparent" placeholder="{{'forgot_password.t_email_placeholder'|translate}}"
                    autocomplete="t_email" formControlName="t_email">
                </div>
                <div class="input-group mb-3 err-min-space">
                  <span *ngIf="form.controls.t_email.touched && !form.controls.t_email.valid ">
                    <small class="text-danger"
                      *ngFor="let error of form.controls.t_email['errorMessages']">{{error|translate}}</small>
                  </span>
                </div>
                <div class="col-12 d-grid">
                  <button [disabled]="(t_email == null || t_email.length == 0)" type="button"
                    class="btn btn-primary px-4" (click)="passwordRecovery()">{{'forgot_password.buttons.recovery'|translate}}</button>
                </div>
                <div class="col-12 d-flex justify-content-center">
                  <button type="button" class="btn btn-link px-0" (click)="loginPage()">{{'forgot_password.buttons.login'|translate}}</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </div>
    <div class="col-lg-6 justify-content-center d-none d-sm-block">
      <div class="text-center"><img class="img-fluid" width="300px" src="../../../../assets/images/ti-finanzio.png">
      </div>
    </div>
  </div>
</div>