import { pattern, required } from '@rxweb/reactive-form-validators';
import { messages, regex_form_control } from '../../../core/utility/form-constant';

export class ForgotPasswordFormModel {
  @required({ message: messages.t_username.required })
  @pattern({ expression: { onlyAlpha: regex_form_control.t_email_regex },  message: messages.t_username.pattern })
  t_email: string;

  constructor() {
    this.t_email = null;
  }
}