import { IndividualConfig } from "ngx-toastr";

export const toastConfig: IndividualConfig = {
    /**
    * disable both timeOut and extendedTimeOut
    */
    disableTimeOut: false,
    /**
    * toast time to live in milliseconds
    */
    timeOut: 1500,
    /**
    * toast show close button
    */
    closeButton: false,
    /**
     * time to close after a user hovers over toast
     */
    extendedTimeOut: 1000,
    /**
     * show toast progress bar
     */
    progressBar: true,
    /**
     * changes toast progress bar animation
     */
    progressAnimation: "decreasing",
    /**
     * render html in toast message (possibly unsafe)
     */
    enableHtml: false,
    /**
     * css class on toast component
     */
    toastClass: 'ngx-toastr',
    /**
     * css class on toast container
     */
    positionClass: 'toast-top-right',
    /**
     * css class on toast title
     */
    titleClass: 'toast-title',
    /**
     * css class on toast message
     */
    messageClass: 'toast-message',
    /**
     * animation easing on toast
     */
    easing: 'ease-in',
    /**
     * animation ease time on toast
     */
    easeTime: 300,
    /**
     * clicking on toast dismisses it
     */
    tapToDismiss: true,
    /**
     * Helps show toast from a websocket or from event outside Angular
     */
    onActivateTick: false,
    /**
     * New toast placement
     */
    newestOnTop: true,
    payload: undefined
}