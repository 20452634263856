<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left fw-bold">{{'messages_service.'+title | translate}}</h4>
    <button type="button" class="btn btn-link btn-link--reset btn-sm text-decoration-none align-self-start"
      aria-label="Close" (click)="respond()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="word-wrap: break-word;">
    <div *ngFor="let message of messages">
      {{message | translate}}
    </div>
  </div>
  <div class="modal-footer">
    <ng-container *ngIf="options.length">
      <ng-container *ngFor="let option of options">
        <ng-container [ngSwitch]="option.type">
          <button *ngSwitchCase="'button'" type="button" class="btn btn-primary"
          (click)="respond(option)">{{'messages_service.'+option.name | translate}}</button>
          <button *ngSwitchCase="'link'" type="button" class="btn btn-link"
          (click)="respond(option)">{{'messages_service.'+option.name | translate}}</button>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
