import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { map, Observable } from 'rxjs';
import { Customer, CustomerSearchRequest, Product, ProductSearchRequest, ResponsePaginatedDTO, RisultatoDTO, User, UserResponse, UserSearchRequest } from '../../models';
import { nLengthResultSearchSelect } from '../../utility';
import { environment } from './../../../../environments/environment';

export const GCBFP = "utility/1.0/getCityByFilterPaginated";
export const GCCBT = "utility/1.0/getCodeableConceptByType";
export const GCCBT2 = "utility/1.0/getCodeableConceptByType";
export const GUBFP = "utility/1.0/getUslByFilterPaginated";
export const GLC = "customer/1.0/getListCustomer";
export const GLP = "product/1.0/getListProduct";
export const GLUBYR = "user/1.0/getListUserByRole";
export const GCLUBYR = "user/1.0/getCompositeListUserByRole";

@Injectable({
	providedIn: "root",
})
export class UtilityService {
	constructor(private httpClient: HttpClient) { }

	getListCustomersByFilterPaginated(customerSearchReq: CustomerSearchRequest): Observable<RisultatoDTO<ResponsePaginatedDTO<Customer>>> {
		return this.httpClient.post<RisultatoDTO<ResponsePaginatedDTO<Customer>>>(`${environment.apiBaseUrl}${environment.pathTifinanzio}${GLC}`, customerSearchReq)
			.pipe(
				map(value => ({ ...value, data: (value.data ? { ...value.data, lista_oggetti: value.data.lista_oggetti.map((el) => this.transformCustomer(el)) } : null) })				
			)) as Observable<RisultatoDTO<ResponsePaginatedDTO<Customer>>>;
	}

	getListProductsByFilterPaginated(productSearchReq: ProductSearchRequest): Observable<RisultatoDTO<ResponsePaginatedDTO<Product>>> {
		return this.httpClient.post<RisultatoDTO<ResponsePaginatedDTO<Product>>>(`${environment.apiBaseUrl}${environment.pathTifinanzio}${GLP}`, productSearchReq);
	}

	/*getListUsersByFilterPaginated(userSearchReq: UserSearchRequest): Observable<RisultatoDTO<ResponsePaginatedDTO<UserResponse>>> {
		return this.httpClient.post<RisultatoDTO<ResponsePaginatedDTO<UserResponse>>>(`${environment.apiBaseUrl}${environment.pathTifinanzio}${GLU}`, userSearchReq);
	}*/

	getListUserByRole(userSearchRequest: UserSearchRequest): Observable<RisultatoDTO<ResponsePaginatedDTO<UserResponse>>> {
		return this.httpClient.post<RisultatoDTO<ResponsePaginatedDTO<User>>>(`${environment.apiBaseUrl}${environment.pathTifinanzio}${GCLUBYR}`, userSearchRequest)
		.pipe(
			map(value => ({ ...value, data: (value.data ? { ...value.data, lista_oggetti: value.data.lista_oggetti.map((el) => this.transformUser(el)) } : null) })
		 )) as Observable<RisultatoDTO<ResponsePaginatedDTO<UserResponse>>>;
	}

	getListStages() {
		return [
			{ n_id: 1, t_name: "Fase 1", t_description: "Fase 1" },
			{ n_id: 2, t_name: "Fase 2", t_description: "Fase 2" }
		]
	}

	truncateList(elements: any[]) {
		return elements.length <= nLengthResultSearchSelect ? elements : elements.slice(0, nLengthResultSearchSelect);
	}

	transformCustomer(customer: Customer): any {
		return ({
			...customer,
			t_company_name_vat: customer.t_company_name + ", " + customer.t_vat_number
		});
	}

	transformUser(user: User): any {
		return ({
			...user,
			t_name_surname_cf: user.t_name + ", " + user.t_surname + " " + user.t_fiscal_code,
		});
	}
}
