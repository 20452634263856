<div [formGroup]="form" *ngIf="form" class="row d-block m-0 p-0">
    <mat-card-header class="mx-0 px-0">
        <mat-card-title>            
            <div class="row pt-2 pb-2">  
                <div class="col-md-4 d-flex justify-content-start p-1">
                    {{ 'product.search.title' | translate }} 
                </div>               
                <div class="col-md-8 d-flex justify-content-end p-1">
                    <button type="button" class="btn btn-outline-primary mx-1" (click)="openCreate()" [disabled]="bDisabledNewProductBtn">
                        <i class="fa fa-plus" aria-hidden="true"></i>
                        <span class="p-2">{{'product.search.form.buttons.new' | translate}}</span>
                    </button>
                </div>
            </div>
        </mat-card-title>       
    </mat-card-header>
    <mat-card-content class="py-0 px-0 pb-3">        
        <div class="row col-md-6 m-0 p-0">                        
            <div class="form-group">
                <div class="custom-ng-select">
                    <label for="n_id" class="form-label"> {{'product.search.form.t_name' | translate}} </label>
                    <ng-select appearance="outline" [items]="products" placeholder="{{'product.search.form.t_name_placeholder'| translate}}" notFoundText="{{'messages.no_elements'| translate}}"
                        bindLabel="t_name" bindValue="n_id" formControlName="n_id" (change)="onChangeValue()"  (keyup)="filterProduct($event)" (clear)="onChangeValue()">
                        <ng-template ng-option-tmp let-item="item">
                        <div title="{{item.t_description }}">{{item.t_name}} </div>
                        </ng-template>
                    </ng-select>
                </div>                        
            </div>           
        </div>          
    </mat-card-content>
</div>


